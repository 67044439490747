import React,{useState,useEffect,useRef} from 'react';
import {Link} from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { API_BASE_URL } from '../../src/constant';
import { ThreeDots } from 'react-loader-spinner';


const FormC = () => {
const [training, setTraining] = useState([]);
const [textInputs, setTextInputs] = useState([]);
const [formCStatus,setFormcStatus] = useState('');
const [selectedtrainings,setselectedTrainings] = useState([]);
const [loder, setLoder] = useState(false);
const lock = useRef(true);

const getTraining = ()=>{
    fetch(`${API_BASE_URL}Masters/get_trainings`, {method:'GET',redirect: 'follow'}) 
    .then(response => response.json())
    .then((rsp) => { 
        setTraining(rsp.trainings);
    })
    .catch(error => console.log('error', error));
}
const getPMPStatus = () => {
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/get_pmp_status`, requestOptions)
    .then(response => response.json())
    .then((rsp) => { 
        setFormcStatus(rsp.formCStatus)
    })
    .catch(error => console.log('error', error));   
}
const getFormCData = ()=>{
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/get_formc_user_data`, requestOptions)
    .then(response => response.json())
    .then((rsp) => { 
        let merged_arr =[];
        rsp.formC_data.cdata.formc.map((efc)=>{
            merged_arr.push(efc.answer)
        })
        let combinedArray = merged_arr.map(arr => arr).flat();
        setTextInputs(combinedArray);
        const options = rsp.formC_data.trainings.map(value => ({ id:value.training_master_id, name: value.training_name}));
        setselectedTrainings(options);
    })
    .catch(error => console.log('error', error));
    }

useEffect(()=>{
    if(lock.current) {
        lock.current=false;
        getFormCData();
        getTraining();
        getPMPStatus();
    }
})
const onSelect = (e)=> {
    setselectedTrainings(e);
}

const onRemove = (e)=> {setselectedTrainings(e)}

const handleTextChange = (index, event) => {
const newTextInputs = [...textInputs];
newTextInputs[index] = event.target.value;
setTextInputs(newTextInputs);
};

const getLength = (i) =>{
    if (textInputs[i] && textInputs[i].length<30) {
        alert("Remarks should be  more than 30 characters")
    }
}

const charactersLeft = (index) => {
if (textInputs[index]) {
    return 500 - textInputs[index].length;
}
return 500;
};

const saveAsDraft = (status)=>{ 
    setLoder(true); 
    var totalfield = Object.values(textInputs).filter(Boolean).length;
    if(totalfield != 9 &&  status == 2){
        alert('Please  fill all fields');
        setLoder(false)
        return false;
    }
    let formcData = {
        "formc": [
        {
            "questions": "1. Top 3 Achievements during Performance Assessment year",
            "answer": [textInputs[0], textInputs[1], textInputs[2]],
            "coremark":""
        },
        {
            "questions": "2.What I could have done better during Performance Assessment year",
            "answer": [textInputs[3], textInputs[4], textInputs[5]],
            "coremark":""
        },
        {
            "questions": "3.My improvement goals for next year",
            "answer": [textInputs[6], textInputs[7], textInputs[8]],
            "coremark":""
        }
        ]
    }
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    let raw = JSON.stringify({
        "formcData": formcData,
        "status":status,
        "training":selectedtrainings
    });
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/save_form_c_emp_data`, requestOptions)
    .then(response => response.json())
    .then(result => {
        alert(result.message);
        setLoder(false)
        if(status==2){
            window.location.href='/dashboard'
        }
    })
    .catch(error => console.log('error', error))
}

return (
<div class="container-fluid">
    <div class="block-header py-lg-4 py-3">
        <div class="row g-3">
            <div class="col-md-12 col-sm-12">
                <h5 class="card-title">Form C</h5>
                <h5 class="card-title">फॉर्म सी</h5>
            </div>
        </div>
    </div>
    <div class="row g-2 clearfix row-deck">
        <div class="card ">
                <h6 class="mt-2 border bg-red p-2">1.Top 3 Achievements during performance assessment year<br/>1.प्रदर्शन मूल्यांकन वर्ष के दौरान शीर्ष 3 उपलब्धियाँ</h6>
                <table class="">
                    <tbody>
                        <tr class="m-2">
                            <td class="w-30"><label class="fw-bold fs-11">Achievement 1/उपलब्धि 1</label><textarea class="form-control fs-11 p-3"  maxLength="500" onBlur={() => getLength(0)} onChange={(event) => handleTextChange(0, event)} value={textInputs[0]}></textarea><span>Characters Left: {charactersLeft(0)}</span></td> 
                        </tr>
                        <tr class="m-2">
                            <td class=""><label class="fw-bold fs-11">Achievement 2/उपलब्धि 2</label><textarea class="form-control fs-11 p-3"  maxLength="500" onBlur={() => getLength(1)} onChange={(event) => handleTextChange(1, event)} value={textInputs[1]}></textarea><span>Characters Left: {charactersLeft(1)}</span></td>
                        </tr>
                        <tr class="m-2">
                            <td class=""><label class="fw-bold fs-11">Achievement 3/उपलब्धि 3</label><textarea class="form-control fs-11 p-3"  maxLength="500" onBlur={() => getLength(2)} onChange={(event) => handleTextChange(2, event)} value={textInputs[2]}></textarea><span>Characters Left: {charactersLeft(2)}</span></td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <h6 class=" mt-2 border bg-red p-2">2.What I could have done better during performance assessment year<br/>प्रदर्शन मूल्यांकन वर्ष के दौरान मैं क्या बेहतर कर सकता था </h6>		
                <table class="">
                <tbody>
                    <tr class="m-2">
                        <td class="w-30"><label class="fw-bold fs-11">Activity 1/गतिविधि 1</label><textarea class="form-control fs-11 p-3"  maxLength="500" onBlur={() => getLength(3)} onChange={(event) => handleTextChange(3, event)} value={textInputs[3]}></textarea><span>Characters Left: {charactersLeft(3)}</span></td>
                        
                    </tr>
                    <tr class="m-2">
                        <td class=""><label class="fw-bold fs-11">Activity 2/गतिविधि 2</label><textarea class="form-control fs-11 p-3"  maxLength="500" onBlur={() => getLength(4)} onChange={(event) => handleTextChange(4, event)} value={textInputs[4]}></textarea><span>Characters Left: {charactersLeft(4)}</span></td>
                        
                    </tr>
                    <tr class="m-2">
                        <td class=""><label class="fw-bold fs-11">Activity 3/गतिविधि 3</label><textarea class="form-control fs-11 p-3"  maxLength="500" onBlur={() => getLength(5)} onChange={(event) => handleTextChange(5, event)} value={textInputs[5]} ></textarea><span>Characters Left: {charactersLeft(5)}</span></td>
                        
                    </tr>                       
                </tbody>
                </table>
                <br/>
                <h6 class=" mt-2 border bg-red p-2">3.My improvement goals for next year<br/>अगले वर्ष के लिए मेरे सुधार लक्ष्य </h6>		
                <table class="">
                <tbody>
                    <tr class="m-2">
                        <td class="w-30"><label class="fw-bold fs-11">Goal 1/लक्ष्य 1</label><textarea class="form-control fs-11 p-3"  maxLength="500" onBlur={() => getLength(6)} onChange={(event) => handleTextChange(6, event)} value={textInputs[6]}></textarea><span>Characters Left: {charactersLeft(6)}</span></td>
                    </tr>
                    <tr class="m-2">
                        <td class=""><label class="fw-bold fs-11">Goal 2/लक्ष्य 2</label><textarea class="form-control fs-11 p-3"  maxLength="500" onBlur={() => getLength(7)} onChange={(event) => handleTextChange(7, event)} value={textInputs[7]}></textarea><span>Characters Left: {charactersLeft(7)}</span></td>
                    </tr>
                    <tr class="m-2">
                        <td class=""><label class="fw-bold fs-11">Goal 3/लक्ष्य 3</label><textarea class="form-control fs-11 p-3"  maxLength="500" onBlur={() => getLength(8)} onChange={(event) => handleTextChange(8, event)} value={textInputs[8]}></textarea><span>Characters Left: {charactersLeft(8)}</span></td>
                    </tr>                       
                </tbody>
                </table>                
                <br/>
                <h6 class=" mt-2 border bg-red p-2">Training</h6>
                <table class="">
                    <tbody>
                        <tr class="m-12">
                            <td class="w-90">
                                <Multiselect 
                                    options={training && training.map((tr,i)=>{
                                        return {name: tr.training_name, id : tr.training_master_id};
                                    })}
                                    displayValue="name"
                                    selectedValues={selectedtrainings}
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    selectionLimit={3}
                                />
                            </td>
                        </tr>                      
                    </tbody>
                </table>
                <br/>
                <div class="row">
                    <div class="col-8">
                    <ThreeDots
                    visible={loder}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                    </div>
                    <div class="col-4">
                        <Link to="/dashboard" style={{marginRight:"10px"}} className="btn btn-primary text-white">Go to Dashboard</Link>
                        {formCStatus!=0?"":
                        <><Link to="#" style={{marginRight:"10px"}} onClick={()=>{saveAsDraft(1)}} class="btn btn-primary text-white">Save as Draft</Link>
                        <button disabled={loder} onClick={()=>{saveAsDraft(2)}} className="btn btn-primary text-white">Submit</button></>}
                    </div>
                </div><br/>
            </div>
    </div>
</div> 

);
};

export default FormC;