import React, {useEffect,useState,useRef} from 'react';
import { API_BASE_URL } from '../../src/constant';
import {Link} from 'react-router-dom';
import { useParams } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';


const Competencies = () => {
    const [competencies, setCompetencies] = useState([]); 
    const [textInputs, setTextInputs] = useState([]);
    const [COFeedback, setCOFeedback] = useState({});
    const [COCompetency, setCOCompetency] = useState({});
    const [competenctComplete, setCompetenctComplete] = useState(0);
    const [loder, setLoder] = useState(false);
    const lock = useRef(true);
    let { empId } = useParams();

    const getCompetencies = () =>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'POST',
            body: JSON.stringify({"empId": empId}),
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_user_competency_form`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { console.log(rsp.compet_data);
            setCompetencies(rsp.compet_data)
            setCompetenctComplete(rsp.compet_data[0].co_rating);
        })
        .catch(error => console.log('error', error));
    }
    useEffect(()=>{
        if(lock.current) {
        lock.current = false;
        getCompetencies()
        }
    },[])

    const getCompVal = (e)=>{ 
        setCOCompetency({
            ...COCompetency,
            [e.target.name]: e.target.value
        });
    }

    const handleTextChange = (index, event,compdataid) => {
    const newTextInputs = [...textInputs];
    newTextInputs[index] = event.target.value;
    setTextInputs(newTextInputs);
    setCOFeedback({
        ...COFeedback,
        [compdataid]: event.target.value
    });
    };

    const charactersLeft = (index) => {
    if (textInputs[index]) {
        return 500 - textInputs[index].length;
    }
    return 500;
    };

    const handleSubmit = () => {
        setLoder(true); 
        if(Object.keys(COCompetency).length!==5){
            alert('Please  fill all competencies');
            setLoder(false)
            return false;
        }
        if(Object.keys(COFeedback).length!==5){
            alert('Please  fill all feedback');
            setLoder(false)
            return false;
        }
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        let raw = JSON.stringify({
            "comptenctData": COCompetency,
            "feedback": COFeedback,
            "empId":empId
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/update_competency_form_co_data`, requestOptions)
            .then(response => response.json())
            .then(result => {
                alert(result.message)
                setLoder(false)
                if(result.status == true){
                    window.location.href='/co-kpi-assessment/'+empId
                }
            })
            .catch(error => console.log('error', error))
    };

    return (
    <div className="container-fluid">
    <div className="block-header py-lg-4 py-3">
        <div className="row g-3">
            <div className="col-md-12 col-sm-12">
                <h2 className="m-0 fs-5">Competency Form<br/>योग्यता प्रपत्र</h2>
            </div>
        </div>
    </div>
    {competencies && competencies.map((comp,i)=>{
    return(
    <div className="row mb-2">
        <div className="card">
            <div className="card-header">
                <strong>{comp.objective}</strong>
            </div>
            <div className="card-body">
                {comp.competancy_name}
            </div>
            <div className="row p-2 mb-2">
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                        <input className="form-check-input p-2 ms-1" type="radio"  checked={comp.emp_rating == '0.8'} data-parsley-errors-container="#error-radio"  />
                        <span className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 1</b><br/>{comp.level1}</span>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                        <input className="form-check-input p-2 ms-1" type="radio" checked={comp.emp_rating == '1.5'} data-parsley-errors-container="#error-radio"  />
                        <span className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 2</b><br/>{comp.level2}</span>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                        <input className="form-check-input p-2 ms-1" type="radio"  checked={comp.emp_rating == '2.5'} data-parsley-errors-container="#error-radio"  />
                        <span className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 3</b><br/>{comp.level3}</span>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                        <input className="form-check-input p-2 ms-1" type="radio" checked={comp.emp_rating == '3.25'} data-parsley-errors-container="#error-radio"  />
                        <span className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 4</b><br/>{comp.level4}</span>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                        <input className="form-check-input p-2 ms-1" type="radio"  checked={comp.emp_rating == '4'} data-parsley-errors-container="#error-radio"  />
                        <span className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 5</b><br/>{comp.level5}</span>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                        <input className="form-check-input p-2 ms-1" type="radio" checked={comp.emp_rating == '5'}  data-parsley-errors-container="#error-radio"  />
                        <span className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 6</b><br/>{comp.level6}</span>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <strong>Controller's Review</strong>
            </div>
            <div className="row p-2 mb-2">
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                        {comp.co_rating!='0'?<input className="form-check-input p-2 ms-1" type="radio" checked={comp.co_rating=='0.8'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio"  name={comp.emp_competancy_data_id} value="0.8" onChange={(e)=>getCompVal(e)}  data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                        <label className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 1</b></label>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                    {comp.co_rating!='0'?<input className="form-check-input p-2 ms-1" type="radio" checked={comp.co_rating=='1.5'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio"  name={comp.emp_competancy_data_id} value="1.5" onChange={(e)=>getCompVal(e)}  data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                        <label className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 2</b></label>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                    {comp.co_rating!='0'?<input className="form-check-input p-2 ms-1" type="radio" checked={comp.co_rating=='2.5'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio"  name={comp.emp_competancy_data_id} value="2.5" onChange={(e)=>getCompVal(e)}  data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                        <label className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 3</b></label>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                    {comp.co_rating!='0'?<input className="form-check-input p-2 ms-1" type="radio" checked={comp.co_rating=='3.25'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio"  name={comp.emp_competancy_data_id} value="3.25" onChange={(e)=>getCompVal(e)}  data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                        <label className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 4</b></label>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                    {comp.co_rating!='0'?<input className="form-check-input p-2 ms-1" type="radio" checked={comp.co_rating=='4'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio"  name={comp.emp_competancy_data_id} value="4" onChange={(e)=>getCompVal(e)}  data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                        <label className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 5</b></label>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="form-check border p-2">
                    {comp.co_rating!='0'?<input className="form-check-input p-2 ms-1" type="radio" checked={comp.co_rating=='5'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio"  name={comp.emp_competancy_data_id} value="5" onChange={(e)=>getCompVal(e)}  data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                        <label className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 6</b></label>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <strong>Controller's comment</strong>
            </div>
            <div className="form-check p-2">
                <textarea className="form-control" maxLength="500" value={textInputs[i] || comp.co_feedback} onChange={(event) => handleTextChange(i, event,comp.emp_competancy_data_id)} ></textarea><span>Characters Left: {charactersLeft(i)}</span>
            </div>
        </div>
    </div>
    )
    })}
    <br/>
    <div class="row">
        <div class="col-9"> 
        <ThreeDots
        visible={loder}
        height="80"
        width="80"
        color="#4fa94d"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        />
        </div>
        <div class="col-3">
            <Link to="/COEmployeeList" style={{marginRight:"10px"}} className="btn btn-primary text-white">Go to Dashboard</Link>
            {competenctComplete!=0?<Link to={`/co-kpi-assessment/${empId}`} className="btn btn-primary text-white">Go to KPI</Link>:<button disabled={loder} onClick={handleSubmit} className="btn btn-primary text-white">Save & Next</button>}
        </div>
    </div><br/>
</div>

);
};

export default Competencies;