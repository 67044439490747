import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import "./Assets/Styles/App.css"
import Login from "./Pages/Login";
import Instruction from "./Pages/Instruction";
import Layout from "./Components/Layout";
import Dashboard from "./Pages/Dashboard";
import KPIAssessment from "./Pages/KPIAssessment";
import COKPIAssessment from "./Pages/COKPIAssessment";
import Competencies from "./Pages/Competencies";
import COCompetencies from "./Pages/COCompetencies";
import FormC from "./Pages/FormC";
import FormCCO from "./Pages/FormCCO";
import GeneralInformation from "./Pages/GeneralInformation";
import PersonalInformation from "./Pages/PersonalInformation";
import Notifications from "./Pages/Notifications";
import Feedback2 from "./Pages/Feedback2";
import Help from "./Pages/Help";
import Faqs from "./Pages/Faqs";
import COEmployeeList from "./Pages/COEmployeeList";
import EmployeeFullFeedback from "./Pages/EmployeeFullFeedback";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/instruction" element={<Instruction />} />
                <Route path="/" element={<Layout />}>
                {/* Dashboard is the index route of the Layout */}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/kpi-assessment" element={<KPIAssessment />} />
                <Route path="/co-kpi-assessment/:empId" element={<COKPIAssessment />} />
                <Route path="/competencies" element={<Competencies />} />
                <Route path="/co-competencies/:empId" element={<COCompetencies />} />
                <Route path="/FormCCO/:empId" element={<FormCCO />} />
                <Route path="/form-c" element={<FormC />} />
                <Route path="/feedback" element={<Feedback2 />} />
                <Route exact path="/general-info/:empId" element={<GeneralInformation />} />
                <Route path="/personal-info" element={<PersonalInformation />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/help" element={<Help />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="/COEmployeeList" element={<COEmployeeList />} />
                <Route path="/view-feedback" element={<EmployeeFullFeedback />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
