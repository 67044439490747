import { API_BASE_URL } from './constant';
const Checker = (callback=()=>{})=>{
    let token = localStorage.usertoken
    if(!token){
        window.location.href = "./";
        return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}User/userProfile`, requestOptions)
        .then(response => response.json()) 
        .then((rsp) => { 
            if(rsp?.status === false){
                localStorage.removeItem('usertoken')
                localStorage.removeItem('userData')
                window.location.href = "./";
            }else{
                localStorage.setItem('userData',JSON.stringify(rsp?.userData[0]))
                callback(rsp?.userData)
            }
        })
        .catch(error => console.log('error', error));
}
const getProfileInfo = ()=>{
    let isLogged = false;
    if(localStorage.userData){
        return {
            isLogged:true,
            ...JSON.parse(localStorage.userData)
        }
    }
    return {isLogged}
}

export {Checker,getProfileInfo}