import React, {useEffect,useState,useRef} from 'react';
import { API_BASE_URL } from '../../src/constant';
import {Link} from 'react-router-dom';
import {Checker} from '../../src/sessions';


const PersonalInformation = () => {
    const [userData, setUserData] = useState([]);
    const [employeeDetails, setEmployeeDetails] = useState([]);
    const [employeeExperience, setEmployeeExperience] = useState([]);
    const [employeeEducation, setEmployeeEducation] = useState([]);
    const [educational,setEducational] = useState('')
    const [postionHeldFrom,setPostionHeldFrom] = useState('')
    const [isChecked, setIsChecked] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    let lock = useRef(true);
    const get_profile=()=>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}User/userProfile`, requestOptions)
        .then(response => response.json())
        .then((rsp) => {
            setUserData(rsp.userData[0]);
            setEmployeeDetails(rsp.employeeDetails[0])
            setEmployeeExperience(rsp.employeeExperience)
            setEmployeeEducation(rsp.employee_education)
            setEducational(rsp.employee_education[0].qualLevel)
            setPostionHeldFrom(rsp.employeeExperience[0].joining_date)
        })
        .catch(error => console.log('error', error));

    }
    useEffect(()=>{
        if (lock.current){
            get_profile();
            lock.current=false;
        }
    },[])
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const logout = ()=>{
        alert('Please contact administrator');
        localStorage.clear();
    }
    const saveProfile = ()=>{ 
        if(isChecked){
            if(selectedFile ==null){
                alert("Please upload a profile picture");
                return false;
            }
            let token = localStorage.usertoken
            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            const formData = new FormData();
            formData.append("profileImage",selectedFile);
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formData,
                redirect: 'follow',
                dataType: "jsonp"
            };
        fetch(`${API_BASE_URL}User/userProfileUpdate`, requestOptions)
        .then(response => response.json())
        .then(result => {
            alert(result.message);
            if(result.status == true){
                Checker((x)=>{
                    window.location.href='./dashboard'
                })
                
            }
        })
        .catch(error => console.log('error', error))

        }
        else{
            alert('Please select profile confirmation checkbox');
        }
    }
    
    const handleFileChange = (event) => {
        if(event.target.files[0].size<=513000){
            setSelectedFile(event.target.files[0]);
        }
        else{
            alert('Image size should be less then 512KB');
            setSelectedFile(null);
            window.location.reload();
        }
    
    };

    return (
        <div className="container-fluid">
            <div className="block-header py-lg-4 py-3">
                <div className="row g-3">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="m-0 fs-5">General Information, Educational and Experience Details</h2>
                        <h3 className="m-0 fs-5">सामान्य जानकारी, शैक्षिक और अनुभव विवरण</h3>
                    </div>
                </div>
            </div>

            <div className="row g-2 clearfix row-deck">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">General Information Section I</h5>
                        <h5 className="card-title">समान्य जानकारी अनुभाग 1</h5>
                    </div>
                    <div className="card-body">
                        <form className="row g-3" id="basic-form">
                            <div className="col-12">
                                <label className="fw-bold fs-11">Name/पूरा नाम</label>
                                <input type="text" className="form-control" name="" readOnly value={userData.employee_name} placeholder="Your name"/>
                            </div>
                            <div className="col-4">
                                <label className="fw-bold fs-11">Registration Number/रजिस्ट्रेशन नंबर </label>
                                <input type="email" className="form-control" name="" readOnly value={userData.patId}/>
                            </div>
                            <div className="col-4">
                                <label className="fw-bold fs-11">DOB/डी. ओ. बी. </label>
                                <input type="text" className="form-control" name="" readOnly value={employeeDetails.DOB} />
                            </div>
                            <div className="col-4">
                                <label className="fw-bold fs-11">Educational Qualification/एजुकेशन/ प्रोफेशनल योग्यता </label>
                                <input type="text" className="form-control" name="" readOnly value={educational} />
                            </div>
                            {/* <div className="col-3">
                                <label className="fw-bold fs-11">Band/Rankबैंड /रैंक  </label>
                                <input type="text" className="form-control" name="" readOnly value={userData.band}  />
                            </div> */}
                            {/* <div className="col-4">
                                <label className="fw-bold fs-11">Marital Status/मैरिटल स्टेटस /मैरिटल स्टेटस </label>
                                <input type="text" className="form-control" name="" readOnly value={employeeDetails.marital_status} />
                            </div> */}
                            <div className="col-3">
                                <label className="fw-bold fs-11">Department/वर्तमान स्थिति/पदनाम </label>
                                <input type="text" className="form-control" name="" readOnly value={userData.department}/>
                            </div>
                            <div className="col-3">
                                <label className="fw-bold fs-11">Present Position/ Designation/वर्तमान स्थिति/पदनाम </label>
                                <input type="text" className="form-control" name="" readOnly value={userData.designation_name}/>
                            </div>
                            <div className="col-3">
                                <label className="fw-bold fs-11">Position Held From/धारित पद से  </label>
                                <input type="text" className="form-control" name="" readOnly value={postionHeldFrom} />
                            </div>
                            <div className="col-3">
                                <label className="fw-bold fs-11">Place of Posting/पोस्टिंग की जगह </label>
                                <input type="text" className="form-control" name="" readOnly value={userData.DSTitle} />
                            </div>
                            <div className="col-6">
                                <label className="fw-bold fs-11">Date of Joining/अपॉइंटमेंट की तिथि</label>
                                <input type="text" className="form-control" name="" readOnly value={userData.date_of_confirmation} />
                            </div>
                            {/* <div className="col-6">
                                <label className="fw-bold fs-11">Date of Confirmation/पुष्टिकरण की तिथि</label>
                                <input type="text" className="form-control" name="" readOnly value={userData.date_of_confirmation} />
                            </div> */}
                            <div className="col-6">
                                <label className="fw-bold fs-11">Terms of Employment (Regular/Contractual)/रोज़गार की शर्त (नियमित/संविदा)</label>
                                <input type="text" className="form-control" name="" readOnly value={userData.employment_type} />
                            </div>
                            {/* <div className="col-6">
                                <label className="fw-bold fs-11">Due date of Superannuation/सेवानिवृत्ति की नियत तिथि</label>
                                <input type="text" className="form-control" name="" readOnly value={userData.retirement_date} />
                            </div> */}

                            <div className="card-header" id="education">
                                <h5 className="card-title">Education Profile Section II/शिक्षा प्रोफाइल अनुभाग II</h5>
                            </div>
                            {employeeEducation && employeeEducation.map((eedu,i)=>{
                                return (
                                    <>
                                    <div className="col-2">
                                        <label className="fw-bold fs-11">Qualification/योग्यता</label>
                                        <input type="text" className="form-control" name="" readOnly value={eedu.qualLevel} />
                                    </div>
                                    <div className="col-3">
                                        <label className="fw-bold fs-11">Year of Passing/उत्तीर्ण होने का वर्ष</label>
                                        <input type="text" className="form-control" name="" readOnly value={eedu.qualCompletionYear} />
                                    </div>
                                    <div className="col-4">
                                        <label className="fw-bold fs-11">Institute/University/संस्थान/विश्वविद्यालय</label>
                                        <input type="text" className="form-control" name="" readOnly value={eedu.institute} />
                                    </div>
                                    <div className="col-3">
                                        <label className="fw-bold fs-11">Percentage/Grade(%)/प्रतिशत/ग्रेड(%)</label>
                                        <input type="text" className="form-control" name="" readOnly value={eedu.grade} />
                                    </div>
                                    </>
                                )
                            })}
                            {/* <div className="card-header" id="experience">
                                <h5 className="card-title">Experience Profile(Prior to ACFL) Section III/अनुभव प्रोफ़ाइल (ए सी एफ एल से पहले) अनुभाग III</h5>
                            </div>
                            {employeeExperience && employeeExperience.filter((ee)=>ee.exp_in_acfl==='2').map((ex,i)=>{
                                return (
                                    <>
                                    <div className="col-2">
                                        <label className="fw-bold fs-11">From/से</label>
                                        <input type="text" className="form-control" name="" readOnly value={ex.joining_date} />
                                    </div>
                                    <div className="col-2">
                                        <label className="fw-bold fs-11">To/तक</label>
                                        <input type="text" className="form-control" name="" readOnly value={ex.last_working_date}  />
                                    </div>
                                    <div className="col-3">
                                        <label className="fw-bold fs-11">Organization Details/संगठन विवरण</label>
                                        <input type="text" className="form-control" name="" readOnly value={ex.company_name}  />
                                    </div>
                                    
                                    <div className="col-2">
                                        <label className="fw-bold fs-11">Designation/पद का नाम</label>
                                        <input type="text" className="form-control" name="" readOnly value={ex.designation_name}  />
                                    </div>
                                    </>
                                )
                            })} */}
                            <div className="card-header">
                                <h5 className="card-title">Experience Profile(In ACFL) Section III/अनुभव प्रोफ़ाइल (ए सी एफ एल में) अनुभाग III</h5>
                            </div>
                            {employeeExperience && employeeExperience.filter((ee)=>ee.exp_in_acfl==='1').map((ex,i)=>{
                                return (
                                    <>
                                    <div className="col-3">
                                        <label className="fw-bold fs-11">From/से</label>
                                        <input type="text" className="form-control" name="" readOnly value={ex.joining_date} />
                                    </div>
                                    <div className="col-3">
                                        <label className="fw-bold fs-11">To/तक</label>
                                        <input type="text" className="form-control" name="" readOnly value={ex.last_working_date}  />
                                    </div>
                                    <div className="col-3">
                                        <label className="fw-bold fs-11">Organization Details/संगठन विवरण</label>
                                        <input type="text" className="form-control" name="" readOnly value={ex.company_name}  />
                                    </div>
                                    {/* <div className="col-3">
                                        <label className="fw-bold fs-11">Industry/उद्योग</label>
                                        <input type="text" className="form-control" name="" readOnly value={ex.department}  />
                                    </div> */}
                                    <div className="col-3">
                                        <label className="fw-bold fs-11">Designation/पद का नाम</label>
                                        <input type="text" className="form-control" name="" readOnly value={ex.designation_name}  />
                                    </div>
                                    </>
                                )
                            })}
                            {userData.accountStatus==0?<>
                            <div className="col-12">
                                <label className="fw-bold fs-11">Profile Picture</label>
                                <input type="file" accept="image/*" className="form-control" name="ImageStyle" onChange={handleFileChange}/>
                                <strong>Image size should be less then 512KB</strong>
                            </div>

                            <div className="col-12">
                                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/><strong style={{paddingLeft:"10px"}}>All information is correct as per my records and information.</strong>
                            </div>
                            <div className="col-4"></div>
                            <div className="col-2">
                                <Link to="/" onClick={()=>logout()} style={{float:'right'}} className="btn btn-primary text-white">Not Accept</Link>
                            </div>
                            <div className="col-2">
                                <Link to="#" onClick={saveProfile} className="btn btn-primary text-white">Accept & Confirm</Link>
                            </div>
                            <div className="col-4"></div></>
                            :
                            <>
                            <div className="col-5"></div>
                            <div className="col-2">
                                <Link to="/dashboard" className="btn btn-primary text-white">Go to dashboard</Link>
                            </div>
                            <div className="col-5"></div>
                            </>
                            
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
);
};

export default PersonalInformation;