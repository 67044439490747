import React,{useState,useEffect,useRef} from 'react';
import {Link} from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { API_BASE_URL } from '../../src/constant';
import { useParams } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';


const FormCCO = () => {
    const [training, setTraining] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [empTraining, setEmpTraining] = useState([]);
    const [editC_formId,seteditC_formId] = useState(0);
    const [eformc_data,setFormCData] = useState([]);
    const [transfer,setTransfer] = useState(0);
    const [transferText,setTransferText] = useState('');
    const [penPicture,setPenPicture] = useState('');
    const [selectedtrainings,setselectedTrainings] = useState([]);
    const [empDesignation, setEmpDesignation] = useState('');
    const [textInputs, setTextInputs] = useState([]);
    const [isTransferdiv,setIsTransfer] = useState('none');
    const [rating, setRating] = useState('');
    const [empkpistatus, setEmpkpistatus] = useState(0);
    const [loder, setLoder] = useState(false);
    const lock = useRef(true);
    let { empId } = useParams();

    const getTraining = ()=>{
        fetch(`${API_BASE_URL}Masters/get_trainings`, {method:'GET',redirect: 'follow'}) 
        .then(response => response.json())
        .then((rsp) => { 
            setTraining(rsp.trainings);
        })
        .catch(error => console.log('error', error));
    }

    const getDesigantions = ()=>{
        fetch(`${API_BASE_URL}Masters/getDesignation`, {method:'GET',redirect: 'follow'}) 
        .then(response => response.json())
        .then((rsp) => { 
            setDesignations(rsp.designation);
        })
        .catch(error => console.log('error', error));
    }

    const getFormCData = ()=>{
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify({"empId": empId}),
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/get_formc_co_data`, requestOptions)
    .then(response => response.json())
    .then((rsp) => { console.log(rsp)
        seteditC_formId(rsp.formC_data.cid)
        setFormCData(rsp.formC_data.cdata.formc)
        setPenPicture(rsp.formC_data.penPicture)
        setTransfer(rsp.formC_data.transfer)
        setEmpDesignation(rsp.formC_data.empDesignation)
        setEmpkpistatus(rsp.formC_data.status)
        const options = rsp.formC_data.trainings.map(value => ({ id:value.training_master_id, name: value.training_name}));
        setselectedTrainings(options);
        let oldcofeedback = [...textInputs];
        rsp.formC_data.cdata.formc.forEach((k,i)=>{
            oldcofeedback[i] = k.coremark;  
        })
        setTextInputs(oldcofeedback);
    })
    .catch(error => console.log('error', error));
    }

    const getRating = () => {
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'POST',
            body: JSON.stringify({"empId": empId}),
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_emp_rating`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { 
            setRating(rsp.empRating) 
        })
        .catch(error => console.log('error', error));   
    }

    useEffect(()=>{
        if(lock.current) {
            lock.current=false;
            getTraining();
            getFormCData();
            getDesigantions();
            getRating();
        }
    })
    const handleTextChange = (index, event) => { 
    const newTextInputs = [...textInputs];
    newTextInputs[index] = event.target.value;
    setTextInputs(newTextInputs);
    };

    const getLength = (i) =>{
        if (textInputs[i] && textInputs[i].length<30) {
            alert("Remarks should be  more than 30 characters")
        }
    }

    const charactersLeft = (index) => {
    if (textInputs[index]) {
        return 500 - textInputs[index].length;
    }
    return 500;
    };

    const penPictureCharactersLeft = () => {
        return 500 - penPicture.length;
    };

    const onSelect = (e)=> {
        setselectedTrainings(e);
    }

    const saveAsDraft = (status)=>{ 
        setLoder(true)
        var totalfield = Object.values(textInputs).filter(Boolean).length;
        if(totalfield != 3 &&  status == 4){
            alert('Please  fill all fields');
            setLoder(false)
            return false;
        }
        let formcData = {
            "formc": [
            {
                "questions": "1. Top 3 Achievements during Performance Assessment year",
                "answer": eformc_data[0].answer,
                "coremark":textInputs[0]
            },
            {
                "questions": "2.What I could have done better during Performance Assessment year",
                "answer":eformc_data[1].answer,
                "coremark":textInputs[1]
            },
            {
                "questions": "3.My improvement goals for next year",
                "answer": eformc_data[2].answer,
                "coremark":textInputs[2]
            }
            ]
        }
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        let raw = JSON.stringify({
            "formcData": formcData,
            "status":status,
            "training":selectedtrainings,
            "formcid": editC_formId,
            "penPiture": penPicture,
            "updatedDesignation":empDesignation,
            "transfer":transfer,
            "transfertext":transferText,
            "empId": empId,
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_BASE_URL}Appraisal/update_form_c_data`, requestOptions)
        .then(response => response.json())
        .then(result => {
            alert(result.message);
            setLoder(false)
            if(status==4){
                window.location.href='/dashboard'
            }
        })
        .catch(error => console.log('error', error))

    }
    
    const onRemove = (selectedList, removedItem)=> {}
    const getOptionName = (e)=>{
        switch(e){
            case 0 : return "Achievement";
            case 1 : return "Activity";
            case 2 : return "Goal"  
        }
    }
    const isTransfer =  (e)=>{
        let tvalue = e.target.value==1?'':'none';
        setIsTransfer(tvalue)
        setTransfer(e.target.value)
    }

    return (
    <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
            <div className="row g-3">
                <div className="col-md-12 col-sm-12">
                    <h5 className="card-title">Form C</h5>
                    <h5 className="card-title">फॉर्म सी</h5>
                </div>
            </div>
        </div>

        <div className="row g-2 clearfix row-deck">
            <div className="card ">
                {eformc_data && eformc_data.map((cd,i)=>{
                    return (
                        <div key={i}>
                        <h6 className="  mt-2 border bg-red p-2">{cd.questions}</h6>
                        <table className="">
                            <tbody>
                                <tr>
                                    <td><label className="fw-bold fs-11">{getOptionName(i)} 1</label><textarea className="form-control fs-11" readOnly style={{width:"600px"}}>{cd.answer[0]}</textarea></td>
                                    <td><label className="fw-bold fs-11">Controlling officer Comment</label><textarea style={{width:"600px"}} className="form-control fs-11" onBlur={() => getLength(i)} onChange={(event) => handleTextChange(i, event)} maxLength="500">{cd.coremark}</textarea>Characters Left: {charactersLeft(i)}</td>
                                </tr>
                                <tr>
                                    <td><label className="fw-bold fs-11">{getOptionName(i)} 2</label><textarea style={{width:"600px"}} className="form-control fs-11 p-3" readOnly>{cd.answer[1]}</textarea></td>
                                </tr>
                                <tr>
                                    <td><label className="fw-bold fs-11">{getOptionName(i)} 3</label><textarea style={{width:"600px"}} className="form-control fs-11 p-3" readOnly >{cd.answer[2]}</textarea></td>
                                </tr>
                            </tbody>
                        </table>
                        <br/> 
                        </div>
                    )
                })}
                <table className="table table-hover align-middle table-bordered table-striped ">
                    <tbody>
                        <tr>
                            <td className="fw-bold">Transfer</td>
                            <td>
                                <div className="d-flex">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" checked={transfer==1?true:false} onClick={(e)=>{isTransfer(e)}} value="1" name="transfer_status" data-parsley-errors-container="#error-radio" id="flexRadioDefault3" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3" >Yes</label>
                                    </div>
                                    <div className="form-check mx-3">
                                        <input className="form-check-input" type="radio" checked={transfer==0?true:false} name="transfer_status" value="0" id="flexRadioDefault4" onClick={(e)=>{isTransfer(e)}}/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault4">No</label>
                                    </div>
                                    <tr className="m-12" style={{display:isTransferdiv}}>
                                        <td><textarea style={{width:"500px",border:"1px solid #00000069"}} onChange={(e)=>{setTransferText(e.target.value)}} className="form-control fs-11" maxLength="500">{transferText}</textarea></td>
                                    </tr>  
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="fw-bold">Re Designation</td>
                            <td colSpan="">
                                <select className="form-control" name="designation_id" onChange={(e)=>{setEmpDesignation(e.target.value)}}>
                                    <option value="">Select Designation</option>
                                    {designations && designations.map((d,i)=>{
                                        return <option value={d.designation_id} selected={d.designation_id==empDesignation?true:false}>{d.designation_name} ({d.department_name})</option>
                                    })}
                                </select>
                            </td>
                        </tr> 
                        <tr>
                            <td className="fw-bold">Training</td>
                            <td colSpan="">
                                <Multiselect 
                                    options={training && training.map((tr,i)=>{
                                        return {name: tr.training_name, id : tr.training_master_id};
                                    })}
                                    selectedValues={selectedtrainings}
                                    displayValue="name"
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    selectionLimit={3}
                                />
                            </td>
                        </tr>                                                                                              
                    </tbody>
                </table>
                <br/>
                <h6 className="mt-2 border bg-red p-2">Ratings</h6>
                <table className="table table-hover align-middle table-bordered table-striped">
                    <thead>
                        <tr className="m-12">
                            <td className="w-90" colSpan={3} style={{textAlign:"center"}}><strong>Self</strong></td>
                            <td className="w-90" colSpan={3} style={{textAlign:"center"}}><strong>CO</strong></td>
                        </tr>                      
                    </thead>
                    <tbody>
                        <tr className="m-12">
                            <td className="w-90" style={{textAlign:"center"}}><strong>KPI</strong></td>
                            <td className="w-90" style={{textAlign:"center"}}><strong>Competency</strong></td>
                            <td className="w-90" style={{textAlign:"center"}}><strong>Overall Score</strong></td>
                            <td className="w-90" style={{textAlign:"center"}}><strong>KPI</strong></td>
                            <td className="w-90" style={{textAlign:"center"}}><strong>Competency</strong></td>
                            <td className="w-90" style={{textAlign:"center"}}><strong>Overall Score</strong></td>
                        </tr> 
                        <tr className="m-12">
                            <td className="w-90" style={{textAlign:"center"}}>{parseFloat(rating.emp_kpi_score).toFixed(2)}</td>
                            <td className="w-90" style={{textAlign:"center"}}>{parseFloat(rating.emp_competancy_score).toFixed(2)}</td>
                            <td className="w-90" style={{textAlign:"center"}}>{parseFloat(rating.emp_overall_score).toFixed(2)}</td>
                            <td className="w-90" style={{textAlign:"center"}}>{parseFloat(rating.co_kpi_score).toFixed(2)}</td>
                            <td className="w-90" style={{textAlign:"center"}}>{parseFloat(rating.co_competancy_score).toFixed(2)}</td>
                            <td className="w-90" style={{textAlign:"center"}}>{parseFloat(rating.co_overall_score).toFixed(2)}</td>
                        </tr>   
                    </tbody>
                </table>
                <br/>
                <h6 className="mt-2 border bg-red p-2">CO Remarks</h6>
                <table className="">
                    <tbody>
                        <tr className="m-12">
                            <td className="w-90"><textarea className="form-control fs-11 p-3" maxLength="500" onChange={(e)=>{setPenPicture(e.target.value)}} value={penPicture}></textarea><span>Characters Left: {penPictureCharactersLeft()}</span></td>
                        </tr>                      
                    </tbody>
                </table>
                <br/>
                <div className="row">
                    <div className="col-8"> 
                    <ThreeDots
                    visible={loder}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                    </div>
                    <div className="col-4">
                        <Link to="/COEmployeeList" style={{marginRight:"10px"}} className="btn btn-primary text-white">Go to Dashboard</Link>
                        {empkpistatus!=4? 
                        <><Link to="#" onClick={()=>{saveAsDraft(3)}} style={{marginRight:"10px"}} className="btn btn-primary text-white">Save as Draft</Link>
                        <button disabled={loder} onClick={()=>{saveAsDraft(4)}} className="btn btn-primary text-white">Submit</button></>:""}
                    </div>
                </div><br/>
                </div>
        </div>
    </div> 

);
};

export default FormCCO;