import React, {useEffect,useState,useRef} from 'react';
import "../Assets/Styles/KPIAssessment.css"
import { API_BASE_URL } from '../../src/constant';
import {Link} from 'react-router-dom';
import { useParams } from "react-router-dom";

const COKpiAssessment = () => {
const [kpis, setKpis] = useState([]); 
const [total, setTotalScore] = useState(0.00);
const [textInputs, setTextInputs] = useState([]);
const [empFeedback, setEmpFeedback] = useState({});
const [score, setScore] = useState([]);
const [empkpistatus, setEmpkpistatus] = useState(0);
const [disabled,setDisabled] = useState(false);
let { empId } = useParams();
const lock = useRef(true);
const getKpis = () =>{
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify({"empId": empId}),
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/get_kpi_form_co`, requestOptions)
    .then(response => response.json())
    .then((rsp) => { 
        setKpis(rsp.kpi_data)
        setDisabled(rsp.kpi_data[0].department_id==1 && (rsp.kpi_data[0].emp_id != 69)?true:false);
        setTotalScore(rsp.kpi_score)
        let newFeedback = {...empFeedback};
        const newScore = [...score];
        rsp.kpi_data.forEach((k,i)=>{
            newFeedback[k.emp_kpi_data_id] = k.co_feedback; 
            newScore[i] = parseFloat(k.co_score); 
        })
        setEmpFeedback(newFeedback);
        setScore(newScore);
    })
    .catch(error => console.log('error', error));
}
const getEmpRating = ()=>{
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify({"empId": empId}),
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/get_emp_rating`, requestOptions)
    .then(response => response.json())
    .then((rsp) => { 
        setEmpkpistatus(rsp.empRating.status)
    })
    .catch(error => console.log('error', error));
}
useEffect(()=>{
    if(lock.current) {
    lock.current = false;
    getKpis()
    getEmpRating()
    }
},[])

const handleTextChange = (index, event,kpiId) => {
const newTextInputs = [...textInputs];
newTextInputs[index] = event.target.value;
setTextInputs(newTextInputs);
setEmpFeedback({
    ...empFeedback,
    [kpiId]: event.target.value
});
};

const getLength = (i) =>{
    if (textInputs[i] && textInputs[i].length<30) {
        alert("Remarks should be  more than 30 characters")
    }
}
const charactersLeft = (index) => {
if (textInputs[index]) {
    return 500 - textInputs[index].length;
}
return 500;
};

const setRating = (e,weightage,i)=>{
    kpis[i].co_rating = e.target.value;
    kpis[i].co_score = (e.target.value*weightage)/100;
    const newScore = [...score];
    newScore[i] = (e.target.value*weightage)/100;
    setScore(newScore);
    let nscore = 0.0;  
    newScore.forEach(k => {
        nscore += k;
    });
    setTotalScore({TotalScore:nscore}); 
}

const saveAsDraft = (status)=>{  
    let o = Object.fromEntries(Object.entries(empFeedback).filter(([_, v]) => v != '')); 
    if(Object.values(o).length !== kpis.length &&  status == 5){
        alert('Please  fill all KPI feedback');
        return false;
    }
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    let raw = JSON.stringify({
        "kpidata": empFeedback,
        "status":status,
        "empId":empId,
        "score":total,
        "allKPI":kpis,
        "isNonOperation":disabled
    });

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/save_kpi_form_CO_data`, requestOptions)
    .then(response => response.json())
    .then(result => {
        alert(result.message);
        if(result.status == true){
            if(status==5){
                window.location.href='/FormCCO/'+empId
            }
        }
    })
    .catch(error => console.log('error', error))
}
    return (
        <div className="container-fluid">

            <div className="block-header py-lg-4 py-3">
                <div className="row g-3">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="m-0 fs-5"><a href="javascript:void(0);" className="btn btn-sm btn-link ps-0 btn-toggle-fullwidth"><i className="fa fa-arrow-left"></i></a> Performance Objective/प्रदर्शन उद्देश्य</h2>
                    </div>
                </div>
            </div>

            <div className="row g-2 clearfix row-deck">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">KPI Assessment</h5>
                        <h5 className="card-title">केपीआई मूल्यांकन</h5>
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th className="text-center bg-1 align-middle" rowSpan="2" >Sr.No.<br/> क्रमांक</th>
                                <th className="text-center bg-1 align-middle" rowSpan="2" >Objective<br/> उद्देश्य</th>
                                <th className="text-center bg-1 align-middle" rowSpan="2" >Key Performance Indicator<br/> मुख्य निष्पादन संकेतक</th>
                                <th className="text-center bg-1 align-middle" rowSpan="2" >Weightage<br/> महत्व</th>
                                <th className="text-center bg-3" colSpan="3" >Performance<br/>प्रदर्शन</th>
                                <th className="text-center bg-4" colSpan="3" style={{width:750}}>Self KPI Score & Remarks<br/>स्वयं केपीआई स्कोर</th>
                                <th class="text-center bg-5" colspan="3" style={{width:750}}>Controller's Rating on KPI & Remarks<br/>केपीआई और टिप्पणियों पर नियंत्रक की रेटिंग</th>
                            </tr>
                            <tr>
                                <th className="text-center bg-3" >Target <br/>लक्ष्य</th>
                                <th className="text-center bg-3" >Actual <br/>वास्तविक</th>
                                <th className="text-center bg-3" >Achievement% <br/>उपलब्धि%</th>
                                <th className="text-center bg-4" >Rating <br/>रेटिंग</th>
                                <th className="text-center bg-4" >Score <br/>अंक</th>
                                <th className="text-center bg-4" >Self Assessment Remarks <br/>स्व-मूल्यांकन टिप्पणियाँ</th>
                                <th class="text-center bg-5" style={{width:225}}>Rating रेटिंग</th>
                                <th class="text-center bg-5" style={{width:225}}>Score अंक</th>
                                <th class="text-center bg-5" style={{width:325}}>Controller's Remarks नियंत्रक की टिप्पणियाँ</th>
                            </tr>
                            </thead>
                            <tbody>
                            { kpis && kpis.map((kpi,i)=>{ 
                            return(
                                <tr key={i}>
                                    <td className="bg-1">{i+1}</td>
                                    <td className="bg-1"><textarea className="form-control fs-11"  style={{width:"100px",height:"70px" }} readOnly>{kpi.kpi_objective}</textarea></td>
                                    <td className="bg-1" ><textarea readOnly className="form-control fs-11" style={{width:"100px",height:"70px" }} >{kpi.kpi_name}</textarea></td>
                                    <td className="bg-1"><input className="form-control" readOnly value={`${kpi.weightage}%`} type="text"/></td>
                                    <td className="bg-3"><input className="form-control" style={{width:"70px"}} readOnly value={`${kpi.emp_target==0?'-':kpi.emp_target}`} type="text"/></td>

                                    <td className="bg-3"><input className="form-control" style={{width:"70px"}} readOnly value={`${kpi.emp_actual==0?'-':kpi.emp_actual}`} type="text"/></td>

                                    <td className="bg-3"><input className="form-control" style={{width:"65px"}} readOnly value={`${kpi.achievement==0?'-':kpi.achievement}`} type="text"/></td>
                                    <td className="bg-3"><input className="form-control" readOnly value={`${kpi.rating}`} type="text"/></td>
                                    <td className="bg-4"><input className="form-control" style={{width:"70px"}}  readOnly value={`${kpi.score}`} type="text"/></td>
                                    <td className="bg-4"><textarea className="form-control fs-11"  style={{width:"100px",height:"70px" }} value={kpi.emp_feedback} ></textarea></td>
                                    <td className="bg-4">
                                        <select style={{width:'60px'}} disabled={disabled} onChange={(e)=>setRating(e,kpi.weightage,i)} name={kpi.emp_kpi_data_id} className="form-select" defaultValue={`${kpi.co_rating}`} >
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </td>

                                    <td className="bg-4"><input className="form-control" style={{width:"60px"}} readOnly value={`${kpi.co_score}`} type="text"/></td>
                                    <td className="bg-4"><textarea className="form-control fs-11 w-auto" maxLength="500" value={textInputs[i] || kpi.co_feedback} onBlur={() => getLength(i)} onChange={(event) => handleTextChange(i, event,kpi.emp_kpi_data_id)}></textarea><span>Characters Left: {charactersLeft(i)}</span></td>
                                </tr>                               
                                )
                            }) }
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-9"></div>
                        <div className="col-md-3">
                            <label>Total Score</label>
                            <input type="text" readOnly value={parseFloat(total.TotalScore).toFixed(2)} className="form-control"/>
                        </div>
                    </div>
                    <br/>

                    <div className="row">
                    <div className="col-8"> </div>
                        <div className="col-4">
                            <Link to="/COEmployeeList" style={{marginRight:"10px"}} className="btn btn-primary text-white">Go to Dashboard</Link>
                            {empkpistatus==3?<>
                            <Link to={`/FormCCO/${empId}`} className="btn btn-primary text-white">Go to form C</Link></>
                            :<>
                            <Link to="#" style={{marginRight:"10px"}} className="btn btn-primary text-white" onClick={()=>{saveAsDraft(4)}}>Save as Draft</Link>
                            <Link to="#" className="btn btn-primary text-white" onClick={()=>{saveAsDraft(5)}}>Save & next</Link></>
                            }
                        </div>
                    </div><br/>
                </div>
            </div>
        </div>
    );
};

export default COKpiAssessment;