import React from 'react';

const Notifications = () => {
    return (
        <div className="container-fluid">

            <div className="block-header py-lg-4 py-3">
                <div className="row g-3">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="m-0 fs-5">Notification / अधिसूचना</h2>
                    </div>
                </div>
            </div>

            <div className="row g-2 clearfix row-deck">
                <div className="card">

                    <div className="card-body">
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>

                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>
                        <div className="card box-shadow-gray mb-3">
                            <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                            <div className="row m-2">
                                <div className="col-md-9">
                                    <label className="badge bg-success text-white mb-0 w-auto">Lorem Ipsum is
                                        simpl</label>
                                    <p className="mb-0 fw-bold">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. </p>
                                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                </div>
                                <div className="col-md-3"><i className="fa fa-clock-o me-1"></i>12 December 2023 at
                                    10.30AM
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    );
};

export default Notifications;