import React, {useEffect,useState,useRef} from 'react';
import { API_BASE_URL } from '../../src/constant';

const Help = () => {
    const [instructions,setinstructions] = useState([]);
    const [instructionsVideo,setinstructionsvideo] = useState([]);
    let isMounted = useRef(true);
    useEffect(()=>{
        if(isMounted.current){
        let token = localStorage.usertoken
        if(!token){
            window.location.href = "./";
            return;
        }
        fetch(`${API_BASE_URL}Masters/getinstructions`,{
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy:'no-referrer',
            body: JSON.stringify({})
        })
        .then(response => response.json())
        .then((res)=>{
            setinstructions(res.instructions)
        })
        fetch(`${API_BASE_URL}Masters/getinstructions_video`,{
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy:'no-referrer',
            body: JSON.stringify({})
        })
        .then(response => response.json())
        .then((res)=>{
            setinstructionsvideo(res.instructions)
        })
        isMounted.current = false;
        }
        },[])
    return (
        <div className="container-fluid">

            <div className="block-header py-lg-4 py-3">
                <div className="row g-3">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="m-0 fs-5">How to fill your Appraisal Form</h2>
                    </div>
                </div>
            </div>

            <div className="row g-2 clearfix row-deck">
                <div className="card">
                    <div className="card-body">
                        <div className="row mb-5">
                            {instructionsVideo.map((insV,i)=>{
                            return (
                            <div className="col-md-6">
                            <iframe
                            height="200"
                            width="450"
                            src={insV.instructions_video}
                            title="video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>
                            </iframe></div>)
                            })} 
                        </div>
                        <h6 className="text-primary">Personal Information<br/>व्यक्तिगत जानकारी</h6>
                        <ul>
                            <li>1.Please verify your personal information, should you need to update, press button <b>Not Accept</b> and please inform HR to update.<br/>कृपया अपनी व्यक्तिगत जानकारी सत्यापित करें, यदि आपको अपडेट करने की आवश्यकता है, तो <b>Not Accept</b> बटन दबाएं और कृपया एचआर को अपडेट करने के लिए सूचित करें। </li><hr/>
                            <li>2.If your personal detail is correct, Upload your passport size colored format photograph and press button <b>Accept & Confirm</b>.<br/>यदि आपका व्यक्तिगत विवरण सही है, तो अपना पासपोर्ट आकार का रंगीन प्रारूप फोटो अपलोड करें और <b>Accept & Confirm</b>.बटन दबाएं। </li>
                        </ul>
                        <h6 className="text-primary">Self-Appraisal<br/>व्यक्तिगत मूल्यांकन</h6>
                        <ul>
                            <li>1.You will move to dashboard from where you need to click on <b>Self-Appraisal</b> button.<br/>आप डैशबोर्ड पर चले जाएंगे जहां से आपको <b>Self-Appraisal</b>  बटन पर क्लिक करना होगा।</li><hr/>
                            <li>2.You will be routed to <b>Competency Form</b> where you need to fill in the form as per the options provided. Once completed, click on <b>Save & Next</b> Button.<br/>आपको <b>Competency Form</b> पर भेजा जाएगा जहां आपको दिए गए विकल्पों के अनुसार फॉर्म भरना होगा। एक बार पूरा होने पर, <b>Save & Next</b> बटन पर क्लिक करें।</li><hr/>
                            <li>3.After saving Competency Form, you will be routed to <b>KPI Assessment</b>. Please check your Actual & Achievement Value against each KPI. If found correct, fill your Comment within the word limit mentioned in <b>Self-Assessment Remarks</b> and then click <b>Save</b>. If you have partially filled in the form and want to complete the rest of the form later, then please press <b>Save as Draft</b> button and logout from the PMP form section. This will keep your data safe within the system. If any value against actual and achievement is wrong, kindly contact your HR representative.<br/>Competency Form save करने के बाद, आपको <b>KPI Assessment</b>. पर भेज दिया जाएगा। कृपया प्रत्येक KPI के सामने दिया गया Actual & Achievement मूल्य जांचें। यदि सही पाया जाता है, तो अपनी टिप्पणी <b>Self-Assessment Remarks</b> में उल्लिखित शब्द सीमा के भीतर भरें और फिर <b>Save</b>. पर क्लिक करें। यदि आपने आंशिक रूप से फॉर्म भरा है और बाकी फॉर्म बाद में भरना चाहते हैं, तो कृपया <b>Save as Draft</b> बटन दबाएं और पीएमपी फॉर्म अनुभाग से लॉगआउट करें। इससे आपका डेटा सिस्टम में सुरक्षित रहेगा. यदि Actual & Achievement के विरुद्ध कोई मूल्य गलत है, तो कृपया अपने HR प्रतिनिधि से संपर्क करें।</li><hr/>
                            <li>4.After completing <b>KPI Assessment</b> form, you will be routed to <b>Form C</b> where you need to fill your top 3 Achievements, 3 improvement areas and 3 Goals for the FY.<br/><b>KPI Assessment</b> फॉर्म पूरा करने के बाद, आपको <b>Form C</b> पर भेजा जाएगा जहां आपको वित्तीय वर्ष के लिए अपनी शीर्ष 3 उपलब्धियां, 3 सुधार क्षेत्र और 3 लक्ष्य भरने होंगे। </li><hr/>
                            <li>5.Select your training needs (maximum of 3 trainings to be selected) and press <b>Submit</b> Button. <br/>अपनी प्रशिक्षण आवश्यकताओं का चयन करें (अधिकतम 3 प्रशिक्षणों का चयन करें) और <b>Submit</b> बटन दबाएँ। </li><hr/>
                            <li>6.After completion of PMP form, the form will then move to Controlling Officer for further review. <br/>पीएमपी फॉर्म पूरा होने के बाद, फॉर्म आगे की समीक्षा के लिए आपके नियंत्रण अधिकारी के पास चला जाएगा।</li><hr/>
                            <li>7.Once your Controlling Officer completes review process on system, you will be notified on email for filling <b>Feedback Form</b>. <br/>एक बार जब आपका नियंत्रण अधिकारी सिस्टम पर समीक्षा प्रक्रिया पूरी कर लेता है, तो आपको <b>Feedback Form</b>.भरने के लिए ईमेल पर सूचित किया जाएगा।</li><hr/>
                            <li>8.Once your controlling officer has reviewed your PMP form, login to your PMP Account. You will be routed to Dashboard, where you will see <b>View Your CO feedback</b> button and <b>Submit your Feedback</b> button. <br/>एक बार जब आपका नियंत्रण अधिकारी आपके पीएमपी फॉर्म की समीक्षा कर ले, तो अपने पीएमपी खाते में लॉगिन करें। आपको डैशबोर्ड पर ले जाया जाएगा, जहां आपको <b>View Your CO feedback</b> बटन और <b>Submit your Feedback</b> बटन दिखाई देगा।</li><hr/>
                            <li>9.By Clicking button <b>View your CO Feedback</b>, you will see your PMP form along with your Controlling Officer’s comments. After reading the comments, click on <b>Submit your Feedback</b>; you will be routed to <b>Feedback Form</b>. Fill in the form carefully and then press <b>Submit</b> Button to complete your PMP Process.<br/><b>View your CO Feedback</b>, बटन पर क्लिक करके, आप अपने नियंत्रण अधिकारी की टिप्पणियों के साथ अपना पीएमपी फॉर्म देखेंगे। टिप्पणियाँ पढ़ने के बाद, <b>Submit your Feedback</b>; पर क्लिक करें; आपको <b>Feedback Form</b>. पर भेज दिया जाएगा। फॉर्म को सावधानीपूर्वक भरें और फिर अपनी पीएमपी प्रक्रिया को पूरा करने के लिए <b>Submit</b> बटन दबाएं।</li>
                        </ul>
                        <h6 className="text-primary">As Controlling Officer<br/>नियंत्रण अधिकारी के रूप में</h6>
                        <ul>
                            <li>1.Once the employee fills the <b>Form C</b>, you will be notified for completing the review for that employee to whom you are a Controlling Officer.<br/>एक बार जब कर्मचारी <b>Form C</b> भर देता है, तो आपको उस कर्मचारी की समीक्षा पूरी करने के लिए सूचित किया जाएगा, जिसके आप नियंत्रण अधिकारी हैं।</li><hr/>
                            <li>2.You will move to dashboard from where you can click on <b>Review Your Employee</b> button.
                            <br/>आप डैशबोर्ड पर चले जाएंगे जहां से आप <b>Review Your Employee</b> बटन पर क्लिक कर सकते हैं।</li><hr/>
                            <li>3.On clicking <b>Review Your Employee</b> button, you will see the list of your employees for whom you need to complete the Performance review.<br/><b>Review Your Employee</b> बटन पर क्लिक करने पर, आपको अपने कर्मचारियों की सूची दिखाई देगी जिनके लिए आपको प्रदर्शन समीक्षा पूरी करने की आवश्यकता है।</li><hr/>
                            <li>4.Press <b>Click</b> button to view the PMP page for each employee.<br/>प्रत्येक कर्मचारी के लिए पीएमपी पृष्ठ देखने के लिए <b>Click</b> बटन दबाएँ।</li><hr/>
                            <li>5.First you need to complete the <b>Competency Form</b> for the employee. Then you will be routed to <b>KPI Assessment Form</b>, and after completing the KPI Assessment, you will be routed to <b>Form C</b>, where you will need to give your remarks on relevant fields filled by employee.<br/>सबसे पहले आपको कर्मचारी के लिए <b>Competency Form</b> पूरा करना होगा। फिर आपको <b>KPI Assessment Form</b>, पर भेज दिया जाएगा, और KPI असेसमेंट पूरा करने के बाद, आपको <b>Form C</b> पर भेज दिया जाएगा, जहां आपको कर्मचारी द्वारा भरे गए प्रासंगिक फ़ील्ड पर अपनी टिप्पणी देनी होगी।</li><hr/>
                            <li>6.Click on <b>Submit</b> button to complete the PMP review process. Once done, an email Notification will be sent to R & MC.<br/>पीएमपी समीक्षा प्रक्रिया को पूरा करने के लिए <b>Submit</b> बटन पर क्लिक करें। एक बार हो जाने पर, R & MC को एक ईमेल अधिसूचना भेजी जाएगी।</li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Help;