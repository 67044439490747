import React,{useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import Footer from './Footer';

const Layout = () => {
    useEffect(() => {
        let token = localStorage.usertoken
        if(!token){
            window.location.href = "./";
            return;
        }
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        document.onkeydown = function(e) {
            if (e.ctrlKey && (e.keyCode === 74 || e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 85 || e.keyCode === 117)) {//Alt+c, Alt+v will also be disabled sadly.
                alert('not allowed');
                return false;
            }
        };
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, []);
 
    return (
        <div>
            <div id="layout" className="theme-cyan">
                <div id="wrapper">
                    <TopNav/>
                    <SideNav/>
                    <div id="main-content" style={{background: "#ffdbd06e"}}>
                    <Outlet />
                    <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
