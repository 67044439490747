import React from 'react';

const Faqs = () => {
    return (
        <div className="container-fluid">

            <div className="block-header py-lg-4 py-3">
                <div className="row g-3">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="m-0 fs-5">Frequently Asked Questions</h2>
                    </div>
                </div>
            </div>

            <div className="row g-2 clearfix row-deck">
                <div className="card">

                    <div className="card-body">
                        <div className="accordion" id="accordionExample1">
                            <div className="accordion-item m-1">
                                <h2 className="accordion-header " id="headingOne1">
                                    <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne1" aria-expanded="true"
                                            aria-controls="collapseOne"><span
                                        className="text-primary fw-bold me-2">Q.</span> Lorem Ipsum is simply dummy text
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s, <div
                                            className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                                    </button>
                                </h2>
                                <div id="collapseOne1" className="accordion-collapse collapse show"
                                     aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <span className="text-primary fw-bold me-2">A.</span> Lorem Ipsum is simply
                                        dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                        industry's standard dummy text ever since the 1500s, when an unknown printer
                                        took a galley of type and scrambled it to make a type specimen book. It has
                                        survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the
                                        release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                        with desktop publishing software like Aldus PageMaker including versions of
                                        Lorem Ipsum.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item m-1">
                                <h2 className="accordion-header" id="headingTwo1">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo1"
                                            aria-expanded="true" aria-controls="collapseTwo1"><span
                                        className="text-primary fw-bold me-2">Q.</span>Lorem Ipsum is simply dummy text
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                                    </button>
                                </h2>
                                <div id="collapseTwo1" className="accordion-collapse collapse"
                                     aria-labelledby="headingTwo1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <span className="text-primary fw-bold me-2">A.</span> Anim pariatur cliche
                                        reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                                        wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on
                                        it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                        helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad
                                        vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                                        raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                                        labore sustainable VHS.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item m-1">
                                <h2 className="accordion-header" id="headingThree1">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree1"
                                            aria-expanded="true" aria-controls="collapseThree1"><span
                                        className="text-primary fw-bold me-2">Q.</span>Lorem Ipsum is simply dummy text
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                                    </button>
                                </h2>
                                <div id="collapseThree1" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <span className="text-primary fw-bold me-2">A.</span>Anim pariatur cliche
                                        reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                                        wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on
                                        it squid single-origin coffee nulla assumenda
                                        shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                        cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                        Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                        haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item m-1">
                                <h2 className="accordion-header" id="headingThree1">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree1"
                                            aria-expanded="true" aria-controls="collapseThree1"><span
                                        className="text-primary fw-bold me-2">Q.</span>Lorem Ipsum is simply dummy text
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                                    </button>
                                </h2>
                                <div id="collapseThree1" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <span className="text-primary fw-bold me-2">A.</span>Anim pariatur cliche
                                        reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                                        wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on
                                        it squid single-origin coffee nulla assumenda
                                        shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                        cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                        Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                        haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item m-1">
                                <h2 className="accordion-header" id="headingThree1">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree1"
                                            aria-expanded="true" aria-controls="collapseThree1"><span
                                        className="text-primary fw-bold me-2">Q.</span>Lorem Ipsum is simply dummy text
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                                    </button>
                                </h2>
                                <div id="collapseThree1" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <span className="text-primary fw-bold me-2">A.</span>Anim pariatur cliche
                                        reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                                        wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on
                                        it squid single-origin coffee nulla assumenda
                                        shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                        cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                        Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                        haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item m-1">
                                <h2 className="accordion-header" id="headingThree1">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree1"
                                            aria-expanded="true" aria-controls="collapseThree1"><span
                                        className="text-primary fw-bold me-2">Q.</span>Lorem Ipsum is simply dummy text
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                                    </button>
                                </h2>
                                <div id="collapseThree1" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <span className="text-primary fw-bold me-2">A.</span>Anim pariatur cliche
                                        reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                                        wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on
                                        it squid single-origin coffee nulla assumenda
                                        shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                        cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                        Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                        haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item m-1">
                                <h2 className="accordion-header" id="headingThree1">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree1"
                                            aria-expanded="true" aria-controls="collapseThree1"><span
                                        className="text-primary fw-bold me-2">Q.</span>Lorem Ipsum is simply dummy text
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                                    </button>
                                </h2>
                                <div id="collapseThree1" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <span className="text-primary fw-bold me-2">A.</span>Anim pariatur cliche
                                        reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                                        wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on
                                        it squid single-origin coffee nulla assumenda
                                        shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                        cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                        Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                        haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item m-1">
                                <h2 className="accordion-header" id="headingThree1">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree1"
                                            aria-expanded="true" aria-controls="collapseThree1"><span
                                        className="text-primary fw-bold me-2">Q.</span>Lorem Ipsum is simply dummy text
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                                    </button>
                                </h2>
                                <div id="collapseThree1" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <span className="text-primary fw-bold me-2">A.</span>Anim pariatur cliche
                                        reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                                        wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on
                                        it squid single-origin coffee nulla assumenda
                                        shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                        cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                        Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                        haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item m-1">
                                <h2 className="accordion-header" id="headingThree1">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree1"
                                            aria-expanded="true" aria-controls="collapseThree1"><span
                                        className="text-primary fw-bold me-2">Q.</span>Lorem Ipsum is simply dummy text
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                        <div className="card-status card-status-left bg-red br-bl-7 br-tl-7"></div>
                                    </button>
                                </h2>
                                <div id="collapseThree1" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body">
                                        <span className="text-primary fw-bold me-2">A.</span>Anim pariatur cliche
                                        reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                                        wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on
                                        it squid single-origin coffee nulla assumenda
                                        shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                        cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                        Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                        haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default Faqs;