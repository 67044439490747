import React, { useEffect, useState, useRef } from 'react';
import { API_BASE_URL } from '../../src/constant';
import { Link } from 'react-router-dom';

const EmployeeFullFeedback = () => {
    const lock = useRef(true);
    const [competency, setCompetency] = useState([]);
    const [kpis, setKpis] = useState([]);
    const [eformc_data,setFormCData] = useState([]);
    const [empTraining,setEmpTraining] = useState([]);
    const [coPenPicture,setCoPenPicture] = useState('');
    const [rating, setRating] = useState('');

    const getCompleteCompetency = () =>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_complete_pmp_feedback`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { console.log(rsp.competency);
            setCompetency(rsp.competency)
        })
        .catch(error => console.log('error', error));
    }

    const getCompleteKPIData = () =>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_kpi_form`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { console.log(rsp);
            setKpis(rsp.kpi_data)
        })
        .catch(error => console.log('error', error));
    }

    const getFormCData = ()=>{
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/get_formc_user_data`, requestOptions)
    .then(response => response.json())
    .then((rsp) => { console.log(rsp)
        setFormCData(rsp.formC_data.cdata.formc)
        setCoPenPicture(rsp.formC_data.penPicture)
        setEmpTraining(rsp.formC_data.trainings)
    })
    .catch(error => console.log('error', error));
    }
    const getRating = () => {
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_pmp_status`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { 
            setRating(rsp.empRating) 
        })
        .catch(error => console.log('error', error));   
    }
    useEffect(()=>{
        if(lock){
            lock.current=false;
            getCompleteCompetency();
            getCompleteKPIData();
            getFormCData();
            getRating();
        }
    },[])

    const getComptencyRating = (r,comp)=>{
        switch(r){
            case 0.8 : return 'Level1: '+ comp.level1; break;
            case 1.5 : return 'Level2: '+comp.level2; break;
            case 2.5 : return 'Level3: '+comp.level3; break;
            case 3.25 : return 'Level4: '+comp.level4; break;
            case 4 : return 'Level5: '+comp.level5; break;
            case 5 : return 'Level6: '+comp.level6; break;
        }
    }
    const getOptionName = (e)=>{
        switch(e){
            case 0 : return "Achivements";
            case 1 : return "Activity";
            case 2 : return "Goal"  
        }
    }
    return (
        <div className="container-fluid">
            <div className="block-header py-lg-4 py-3">
                <div className="row g-3">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="m-0 fs-5">Competency Review</h2>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="table-responsive card">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr className="bg-6 text-center">
                                    <th className="text-white">SNo.</th>
                                    <th className="text-white" style={{width:'100px'}}>Objective</th>
                                    <th className="text-white" style={{width:'250px'}}>Self Rating</th>
                                    <th className="text-white" style={{width:'250px'}}>CO Rating</th>
                                    <th className="text-white">CO Feedback</th>
                                </tr>
                            </thead>
                            <tbody className="bg-7">
                            {competency && competency.map((comp,i)=>{
                                return (
                                <tr>
                                    <td>{i+1}</td>
                                    <td>{comp.objective}</td>
                                    <td>{getComptencyRating(parseFloat(comp.emp_rating),comp)}</td>
                                    <td>{getComptencyRating(parseFloat(comp.co_rating),comp)}</td>
                                    <td><textarea className="form-control" readOnly>{comp.co_feedback}</textarea></td>
                                </tr>)
                            })}    
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="block-header py-lg-4 py-3">
                <div className="row g-3">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="m-0 fs-5">KPI Review</h2>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="table-responsive card">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th className="text-center bg-1 align-middle" rowspan="2" style={{ width: "250px" }}>Key Performance Indicator</th>
                                    <th className="text-center bg-3" colspan="3" style={{ width: "250px" }}>Performance</th>
                                    <th className="text-center bg-4" colspan="3" style={{ width: "750px" }}>Self KPI Score &amp; Remarks </th>
                                    <th className="text-center bg-5" colspan="3" style={{ width: "750px" }}>Controller's Rating on KPI &amp; Remarks</th>
                                </tr>
                                <tr>
                                    <th className="text-center bg-3 magic" style={{ width: "125px" }}>Target <span className="magictext"></span></th>
                                    <th className="text-center bg-3" style={{ width: "125px" }} >Actual</th>
                                    <th className="text-center bg-3" style={{ width: "125px" }} >Achievement% </th>
                                    <th className="text-center bg-4" style={{ width: "225px" }} >Rating</th>
                                    <th className="text-center bg-4" style={{ width: "225px" }} >Score</th>
                                    <th className="text-center bg-4" style={{ width: "325px" }} >Self Assessment Remarks</th>
                                    <th className="text-center bg-5" style={{ width: "225px" }} >Rating </th>
                                    <th className="text-center bg-5" style={{ width: "225px" }} >Score</th>
                                    <th className="text-center bg-5" style={{ width: "325px" }} >Controller's Remarks </th>
                                </tr>
                            </thead>
                            <tbody>
                            {kpis && kpis.map((kpi,i)=>{
                                return (
                                <tr key={i}>
                                    <td className="bg-1 align-middle" style={{ width: "250px" }}><label className="form-control fs-11 w-auto p-2">{kpi.kpi_name}</label></td>
                                    <td className="bg-3 align-middle"><input className="form-control p-2" value={kpi.emp_target==0?'-':kpi.emp_target} type="text" readOnly /></td>
                                    <td className="bg-3 align-middle"><input className="form-control p-2" value={kpi.emp_actual==0?'-':kpi.emp_actual} type="text" readOnly /></td>

                                    <td className="bg-3 align-middle"><input className="form-control p-2" value={kpi.achievement==0?'-':kpi.achievement} type="text" readOnly /></td>

                                    <td className="bg-4 align-middle"><input className="form-control p-2" value={kpi.rating} type="text" readOnly /></td>
                                    <td className="bg-4 align-middle"><input className="form-control p-2" value={kpi.score} type="text" readOnly /></td>
                                    <td className="bg-4 align-middle"><textarea className="form-control fs-11 p-2  w-auto" readOnly>{kpi.emp_feedback}</textarea></td>
                                    <td className="bg-5 align-middle"><input className="form-control p-2" value={kpi.co_rating} type="text" readOnly /></td>
                                    <td className="bg-5 align-middle"><input className="form-control p-2" value={kpi.co_score} type="text" readOnly /></td>
                                    <td className="bg-5 align-middle"><textarea className="form-control fs-11 p-2 w-auto" readOnly>{kpi.co_feedback}</textarea></td>
                                </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row g-2 clearfix row-deck mt-3">
                <h6>Form-C</h6>
                <div className="card">
                {eformc_data && eformc_data.map((cd,i)=>{
                    return (<div key={i}> 
                    <h6 className="mt-2 border bg-red p-2">{cd.questions}</h6>
                    <table>
                        <tbody>
                            <tr className="m-2">
                                <td className="w-50">
                                    <label className="fw-bold fs-11">{getOptionName(i)} 1</label>
                                    <textarea style={{width:"600px"}} className="form-control fs-11" readOnly>{cd.answer[0]}</textarea>
                                    <label className="fw-bold fs-11">{getOptionName(i)} 2</label>
                                    <textarea style={{width:"600px"}} className="form-control fs-11" readOnly>{cd.answer[1]}</textarea>
                                    <label className="fw-bold fs-11">{getOptionName(i)} 3</label>
                                    <textarea style={{width:"600px"}} className="form-control fs-11" readOnly>{cd.answer[2]}</textarea>
                                </td>
                                <td className="w-50">
                                    <label className="fw-bold fs-11">Controlling Officer Comment</label>
                                    <textarea className="form-control fs-11" readOnly style={{ height: "204px",width:"500px" }}>{cd.coremark}</textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>)
                    })}
                </div>
            </div>
            <div className="row g-2 clearfix row-deck mt-3">
                <div className="card">
                    <table className="table table-hover align-middle table-bordered table-striped mt-3">
                        <tbody>
                            <tr>
                                <td className="fw-bold">Self PMP Score</td>
                                <td><input className="form-control w-20" readOnly value={parseFloat(rating.emp_overall_score).toFixed(2)}/></td>
                                <td className="fw-bold"></td>
                                <td className=""></td>
                            </tr>
                            <tr>
                                <td className="fw-bold">CO Final Score</td>
                                <td><input className="form-control w-20" readOnly value={parseFloat(rating.co_overall_score).toFixed(2)}/></td>
                                <td className="fw-bold">Training</td>
                                <td className="">
                                    {empTraining && empTraining.map((et,i)=>{
                                        return et.training_name+", ";
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold">CO Remarks</td>
                                <td colSpan={3}><textarea className="form-control fs-11 p-2" readOnly value={coPenPicture}></textarea></td>
                            </tr>        
                        </tbody>
                    </table>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-5"> </div>
                <div class="col-7">
                    <Link to="/feedback" className="btn btn-primary text-white">Go to Feedback form</Link>
                </div>
            </div><br/>

        </div>

    );
};

export default EmployeeFullFeedback;