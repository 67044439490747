import React, {useEffect,useState,useRef} from 'react';
import { API_BASE_URL,SecretKey,BASE_URL } from '../../src/constant';
import {getProfileInfo} from './../sessions';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { toBePartiallyChecked } from '@testing-library/jest-dom/matchers';

const COEmployeeList = () => {
    const [co_data, set_co_data] = useState([]);
    const [ro_data, set_ro_data] = useState([]);
    const [emp_co, setEmp_co] = useState('')
    const [emp_ro, setEmp_ro] = useState('')
    const  [profileData,setProfileData] = useState({});
    const lock = useRef(true);

    const dataEncypt = (id)=>{
       const encryptedId = CryptoJS.AES.encrypt(id.toString(), 956).toString();
       return encodeURIComponent(encryptedId);
    }
    const get_co_ro = ()=>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_employee_co_ro_details`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { 
            set_co_data(rsp.co_ro_data.co)
            set_ro_data(rsp.co_ro_data.ro)
        })
        .catch(error => console.log('error', error));
    }
    
    const get_emp_co_ro_details=()=>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}User/userProfile`, requestOptions)
        .then(response => response.json())
        .then((rsp) => {
            setEmp_co(rsp.employee_mapping.CO)
            setEmp_ro(rsp.employee_mapping.RO)
        })
        .catch(error => console.log('error', error));

    }

    const getPmpstatus = (status)=>{
        switch(parseInt(status)){
            case 2 : return 'Pending with CO'; break;
            case 3 : return 'In Draft'; break;
            case 4 : return 'Completed'; break;
        }
    }

   useEffect(()=>{
    if (lock.current){
        lock.current = false;
        get_co_ro();
        get_emp_co_ro_details();
    }
    var profile = getProfileInfo();
        setProfileData(profile);
   },[])
    return (
        <>
        <div className="container-fluid" style={{height:"500px"}}>
            
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-10"><h6>You are controlling officer for {co_data.length} Employees<br/> आप {co_data.length}
                            कर्मचारियों के नियंत्रण अधिकारी हैं </h6></div>
                    </div>

                    <div className="table-responsive card">
                        <table className="table mb-0">
                            <thead>
                            <tr className="bg-6 text-center">
                                <th className="text-white">SNo.</th>
                                <th className="text-white">Profile Image</th>
                                <th className="text-white">Emp.ID</th>
                                <th className="text-white">Name</th>
                                <th className="text-white">Email</th>
                                <th className="text-white">Mobile Number</th>
                                <th className="text-white">Designation</th>
                                <th className="text-white">Review Assessment</th>
                            </tr>
                            </thead>
                            <tbody className="bg-7">
                            {co_data.map((co,i)=>{
                            return (
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td><img src={`${BASE_URL}uploads/${co.profile_image !=''?co.profile_image:'user.png'}`} className="avatar lg rounded me-3" alt="User Profile Picture" /></td>
                                <td><Link to={`/general-info/${co.employee_id}`} target="_blank">{co.patId}</Link></td>
                                <td><Link to={`/general-info/${co.employee_id}`} target="_blank">{co.employee_name}</Link></td>
                                <td>{co.employee_email}</td>
                                <td>{co.employee_mobile}</td>
                                <td>{co.designation_name}</td>
                                <td>{co.status==null?
                                    <Link to='#'><span className="badge bg-warning">Pending</span></Link>:
                                    <Link to={`/co-competencies/${co.employee_id}`} ><span className="badge bg-success">{getPmpstatus(co.status)}</span></Link>}
                                </td>
                            </tr>
                            )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
        </>
    );
};

export default COEmployeeList;