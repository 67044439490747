import React from 'react';
const Footer = ()=>{
    return (
    <>
    <div className="clearfix"></div>
    <div className="text-center p-4"  style={{backgroundColor:"rgba(0, 0, 0, 0.05)"}}>
        © 2024 Copyright.
        <a className="text-reset fw-bold" href="https://neuralinfo.in" target="_blank">Developed by Neural Info Solutions Pvt. Ltd.</a>
    </div></>)
}
export default Footer;
