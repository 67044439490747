import React,{useState,useEffect} from 'react';
import "../Assets/Styles/Login.css"
import { API_BASE_URL } from '../../src/constant';
import {Checker} from '../../src/sessions';
const Login = () => {
const [userId, setUserId] = useState("");
const [password, setPassword] = useState("")

useEffect(()=>{
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    document.onkeydown = function(e) { 
        if (e.ctrlKey && (e.keyCode === 74 || e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 85 || e.keyCode === 117)) {//Alt+c, Alt+v will also be disabled sadly.
            alert('not allowed');
            return false;
        }
        
    };
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
},[])
 
const submitLogin = ()=>{
    if(userId === "" || password===""){
        return alert('All fields are required')
    } 
    else{
        let data = {
            userid: userId,
            password : password
        }
       
        fetch(`${API_BASE_URL}Auth/login`,{
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy:'no-referrer',
            body: JSON.stringify(data)
        }).then((response)=> response.json())
        .then((rsp)=>{
            if(rsp?.status === false){
                alert(rsp?.message)
                return;
            }
            if(rsp?.token){
                localStorage.setItem('usertoken',rsp?.token)
                Checker((x)=>{
                    window.location.href='./instruction'
                })
                return
                //window.location.href='./instruction'
            }
        })
    }
}

    return (
        <>
            <div style={{
                backgroundColor: '#fffbf3',
                overflow: 'hidden',
            }}>
            <div id="wrapper">
                <div className="d-flex h100vh">
                    <div className="col-md-6">
                        <img src="assets/images/login.jpg"/>
                    </div>
                    <div className="col-md-6">
                        <div className="card-header mt-6 mb-3">
                            <img src="assets/images/logo.png"/>
                            <hr className="w-60"/>
                        </div>
                        <div className="card-body">
                            <p className="text-primary fw-bold">Welcome to PMP-ACFL 2023-2024 </p>
                                <label className="fw-bold fs-6">Employee ID</label>
                                <input type="test" className="form-control form-control-login mb-2" name="userId" value={userId} required onChange={(e)=>setUserId(e.target.value)} placeholder="Enter employee ID here"/>
                                <label className="fw-bold fs-6">Password</label>
                                <input type="password" className="form-control form-control-login" name="password" value={password} required onChange={(e)=>setPassword(e.target.value)} placeholder="Enter Password here"/>
                                <div className="pt-3">
                                    {/* <p className="mb-1">Forgot your password? <a href="./reset-password">Click Here
                                        to Reset </a></p> */}
                                </div>
                                {/* <div className="form-check my-4">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Remember me
                                    </label>
                                </div> */}
                                <button type="button" className="btn btn-primary w-60 px-3 py-2" onClick={submitLogin}>LOGIN</button>
                            <div className="mt-10">@2023-2024 ACFL. All rights Reserved. Neural Info Solutions Pvt. Ltd.</div>
                            {/* <p><a href="#">Privacy Policy</a> | <a href="#">Terms & Conditions</a> | <a href="#">Cookies
                                Notice</a></p> */}
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </>
    );
};

export default Login;