import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {getProfileInfo} from './../sessions';
import {BASE_URL} from '../../src/constant';

const SideNav = () => {
    const  [profileData,setProfileData] = useState({});
    useEffect(() => {
        var profile = getProfileInfo();
        setProfileData(profile);
    }, []);

    return (
        <>
            {/* new */}
            <div id="left-sidebar" className="sidebar">
				<div className="user-account ">
					<div className="d-flex mb-3 pb-3 border-bottom align-items-center">
						<img src={`${BASE_URL}uploads/${profileData?.profileImage !=''?profileData?.profileImage:'user.png'}`} className="avatar lg rounded me-3" alt="User Profile Picture" />
						<div className="dropdown flex-grow-1">
							<span>Welcome,</span>
							<span className="user-name"><strong>{profileData?.employee_name}</strong></span>
                            <span className="user-name"><strong>{profileData?.patId}</strong></span>
							
						</div>
					</div>
					<div className="row g-3 pb-3 border-bottom">
                    <div className="col">
                        <h6 className="mb-0 fs-11">{profileData?.DSTitle}</h6>
                        <small className="text-muted">Branch </small>
                    </div>
                    <div className="col">
                        <h6 className="mb-0 fs-11">{profileData?.designation_name}</h6>
                        <small className="text-muted">Designation</small>
                    </div>
                	</div>
				</div>

				<div className="tab-content px-0">
					<div className="tab-pane fade show active" id="hr_menu" role="tabpanel">
						<nav className="sidebar-nav">
							<ul className="metismenu list-unstyled">
								<li className="nav-item active"><Link to="/dashboard" className="nav-link"><i className="fa fa-tachometer"></i><span> Dashboard</span></Link></li>
								{/* <li>
									<a href="#" className="has-arrow"><i className="fa fa-users"></i><span>Appraisal Section</span></a>
									<ul className="list-unstyled">
                                        <li><Link to="./competencies">Competency Assessment</Link></li>
                                        <li><Link to="./kpi-assessment">KPI Assessment</Link></li>
                                        <li><Link to="./form-c">Form-C</Link></li>
                                        {/* <li><Link to="./feedback">Feedback Form</Link></li> 
                                    </ul>
								</li> */}
								<li className="nav-item "><Link to="/help" className="nav-link"><i className="fa fa-info-circle"></i><span> Help</span></Link></li>
							</ul>
						</nav>
					</div>
				</div>
			</div>

        </>
)
    ;
};

export default SideNav;