import React, {useEffect,useState,useRef} from 'react';
import { API_BASE_URL } from '../../src/constant';
import {Link} from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';


const Competencies = () => {
    const [competencies, setCompetencies] = useState([]); 
    const [empCompetency, setEmpCompetency] = useState({});
    const [competency, setCompetency] = useState([]);
    const [loder, setLoder] = useState(false);
    const lock = useRef(true);
    const getCompetencies = () =>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_competency_form`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { 
            setCompetencies(rsp.compet_data)
        })
        .catch(error => console.log('error', error));
    }
    const getCompleteCompetency = () =>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_complete_pmp_feedback`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { console.log(rsp.competency);
            let merged_arr=[];
            rsp.competency.map((ec,i)=>{ 
                merged_arr.push(ec.emp_rating);
            })
            setCompetency(merged_arr)
            getCompetencies();
        })
        .catch(error => console.log('error', error));
    }
    useEffect(()=>{
        if(lock.current) {
        lock.current = false;
        //getCompetencies();
        getCompleteCompetency();
        }
    },[])

    const getCompVal = (e)=>{
        setEmpCompetency({
            ...empCompetency,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = () => { 
        setLoder(true); 
        if(Object.keys(empCompetency).length!==5){
            alert('Please  fill all competencies');
            setLoder(false)
            return false;
        }
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        let raw = JSON.stringify({
            "comptenctData": empCompetency,
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/save_competency_form_emp_data`, requestOptions)
            .then(response => response.json())
            .then(result => {
                alert(result.message)
                setLoder(false)
                if(result.status == true){
                    window.location.href='./kpi-assessment'
                }
            })
            .catch(error => console.log('error', error))
    };

    return (
    <div className="container-fluid">
    <div className="block-header py-lg-4 py-3">
        <div className="row g-3">
            <div className="col-md-12 col-sm-12">
                <h2 className="m-0 fs-5">Competency Form<br/>योग्यता प्रपत्र</h2>
            </div>
        </div>
    </div>
    {competencies && competencies.map((comp,i) => {
        return (
        <div className="row mb-2" key={i}>
            <div className="card">
                <div className="card-header">
                    <strong>{comp.objective}</strong>
                </div>
                <div className="card-body">
                    {comp.competancy_name}
                </div>
                <div className="row p-2 mb-2">
                    <div className="col-md-2">
                        <div className="form-check border p-2">
                            {competency.length?<input className="form-check-input p-2 ms-1" checked={competency[i]=='0.8'} type="radio" data-parsley-errors-container="#error-radio"/>:<input className="form-check-input p-2 ms-1" name={comp.competancy_id} type="radio" value="0.8" onChange={(e)=>getCompVal(e)} data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                            <span className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 1</b><br/>{comp.level1}</span>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <div className="form-check border p-2">
                        {competency.length?<input className="form-check-input p-2 ms-1" checked={competency[i]=='1.5'} type="radio" data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1"  type="radio" name={comp.competancy_id} value="1.5" onChange={(e)=>getCompVal(e)} data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                            <span  className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 2</b><br/>{comp.level2}</span>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <div className="form-check border p-2">
                        {competency.length?<input className="form-check-input p-2 ms-1" type="radio"  checked={competency[i]=='2.5'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio"  name={comp.competancy_id} value="2.5" onChange={(e)=>getCompVal(e)} data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                            <span  className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 3</b><br/>{comp.level3}</span>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <div className="form-check border p-2">
                        {competency.length?<input className="form-check-input p-2 ms-1" type="radio" checked={competency[i]=='3.25'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio" name={comp.competancy_id} value="3.25" onChange={(e)=>getCompVal(e)} data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                            <span  className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 4</b><br/>{comp.level4}</span>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <div className="form-check border p-2">
                        {competency.length?<input className="form-check-input p-2 ms-1" type="radio"  checked={competency[i]=='4'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio"  name={comp.competancy_id} value="4" onChange={(e)=>getCompVal(e)} data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                            <span className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 5</b><br/>{comp.level5}</span>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <div className="form-check border p-2">
                        {competency.length?<input className="form-check-input p-2 ms-1" type="radio" checked={competency[i]=='5'} data-parsley-errors-container="#error-radio" />:<input className="form-check-input p-2 ms-1" type="radio"  name={comp.competancy_id} value="5" onChange={(e)=>getCompVal(e)} data-parsley-errors-container="#error-radio" id={`flexRadioDefault${i}`} />}
                            <span className="form-check-label ms-2" htmlFor={`flexRadioDefault${i}`}><b>Option/विकल्प 6</b><br/>{comp.level6}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        )
    })
    }
    <br/>
    <div class="row">
        <div class="col-9"> 
        <ThreeDots
            visible={loder}
            height="80"
            width="80"
            color="#4fa94d"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
        </div>
        <div class="col-3">
            <Link to="/dashboard" style={{marginRight:"10px"}} className="btn btn-primary text-white">Go to Dashboard</Link>
            {competency.length?<Link to="/kpi-assessment" className="btn btn-primary text-white">Go to KPI</Link>:<button disabled={loder} onClick={handleSubmit} className="btn btn-primary text-white">Save & Next</button>}
        </div>
    </div><br/>
</div>
);
};

export default Competencies;