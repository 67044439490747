import React, {useEffect,useState,useRef} from 'react';
import { API_BASE_URL,SecretKey } from '../../src/constant';
import {getProfileInfo} from './../sessions';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const Dashboard = () => {
    const [co_data, set_co_data] = useState([]);
    const [ro_data, set_ro_data] = useState([]);
    const [emp_co, setEmp_co] = useState('')
    const [emp_ro, setEmp_ro] = useState('')
    const [formcStatus,setformcStatus] = useState(0);
    const [profileData,setProfileData] = useState({});

    
    const lock = useRef(true);

    const dataEncypt = (id)=>{
       const encryptedId = CryptoJS.AES.encrypt(id.toString(), 956).toString();
       return encodeURIComponent(encryptedId);
    }
    const get_co_ro = ()=>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_employee_co_ro_details`, requestOptions)
        .then(response => response.json())
        .then((rsp) => {
            set_co_data(rsp.co_ro_data.co)
            set_ro_data(rsp.co_ro_data.ro)
        })
        .catch(error => console.log('error', error));
    }

    const get_formc_status = ()=>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_formc_user_data`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { console.log(rsp);
            setformcStatus(rsp.formC_data.status);
        })
        .catch(error => console.log('error', error));
    }
    
    const get_emp_co_ro_details=()=>{
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}User/userProfile`, requestOptions)
        .then(response => response.json())
        .then((rsp) => {
            setEmp_co(rsp.employee_mapping.CO)
            setEmp_ro(rsp.employee_mapping.RO)
        })
        .catch(error => console.log('error', error));

    }

   useEffect(()=>{
    if (lock.current){
        lock.current = false;
        get_co_ro();
        get_formc_status();
        //get_emp_co_ro_details();
    }
    var profile = getProfileInfo();
        setProfileData(profile);
        if(profile.accountStatus=='0'){
            window.location.href='./personal-info'
            return false;
        }
   },[])
    return (
        <>
        <div className="container-fluid" style={{height:"520px"}}>
            <div className="block-header py-lg-4 py-3">
            <div className="block-header py-lg-4 py-3">
                <div className="row g-3">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="m-0 fs-6">Welcome {profileData?.employee_name} to ACFL Performance Management Process. </h2>
                        <h3 className="m-0 fs-6">एसीएफएल प्रदर्शन प्रबंधन प्रक्रिया में आप का स्वागत है। </h3>
                    </div>
                </div>
            </div>
            </div>
            <div className="row clearfix">
            <div className="col-md-6">
                <div className="row clearfix">
                <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="card">
                    <div className="p-2 text-center">
                        <div className="icon"><img src="assets/images/SelfAssessment.png" className="w-20"/></div>
                        <div className="p-2 text-center">
                            <Link to="/competencies"><button className="btn btn-primary text-white fs-7">Start Self Appraisal</button></Link>
                        </div>
                    </div>
                    </div>
                </div>
                {co_data.length?
                <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="card">
                    <div className="p-2 text-center">   
                    <div className="icon"><img src="assets/images/ControlingReview.png" className="w-20"/></div>
                        <div className="p-2 text-center">
                            <Link to="/COEmployeeList"><button className="btn btn-primary text-white fs-7">Review your Employee</button></Link>
                        </div>
                    </div>
                    </div>
                </div>
                :""}
                </div>
                <br/>
                {formcStatus==4?
                <div className="row clearfix">
                <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="card">
                    <div className="p-2 text-center">
                        <div className="icon"><img src="assets/images/GiveFeedback.png" className="w-20"/></div>
                        <div className="p-2 text-center">
                            <Link to="/view-feedback"><button className="btn btn-primary text-white fs-7">View your CO feedback</button></Link>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="card">
                    <div className="p-2 text-center">   
                    <div className="icon"><img src="assets/images/SubmitFeedback.png" className="w-20"/></div>
                        <div className="p-2 text-center">
                            <Link to="/feedback"><button className="btn btn-primary text-white fs-7">Submit your Feedback</button></Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                :""}
            </div>
                <div className="col-md-6">
                    <div className="col-md-12">
                        {/* <h6>Quick Help</h6>
                        <h6>त्वरित सहायता</h6> */}
                        <img src="assets/images/group_12.png" className="w-100" height="200px"/>
                        <Link to="/help"><button className="btn btn-primary text-white pull-right me-5"
                                style={{marginTop:-44,position:'relative'}}>Click Here/ यहाँ क्लिक करें
                        </button></Link>
                    </div>
                </div>
            </div>

            {/* <div className="row mt-3">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-10"><h6>You are controlling officer for {co_data.length} Employees<br/> आप {co_data.length}
                            कर्मचारियों के नियंत्रण अधिकारी हैं </h6></div>
                        <div className="col-md-2">
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="70"
                                        aria-valuemin="0" aria-valuemax="100" style={{width:'70%'}}>
                                    70%
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive card" style={{overflow:'scroll'}}>
                        <table className="table mb-0">
                            <thead>
                            <tr className="bg-6 text-center">
                                <th className="text-white">SNo.</th>
                                <th className="text-white">Emp.ID</th>
                                <th className="text-white">Name</th>
                                <th className="text-white">Email</th>
                                <th className="text-white">Mobile Number</th>
                                <th className="text-white">Designation</th>
                                <th className="text-white">KPI Assessment</th>
                                <th className="text-white">Competency Assessment</th>
                                <th className="text-white">Form C & Comment</th>
                            </tr>
                            </thead>
                            <tbody className="bg-7">
                            {co_data.map((co,i)=>{
                            return (
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td><Link to={`/general-info/${co.employee_id}`} target="_blank">{co.patId}</Link></td>
                                <td><Link to={`/general-info/${co.employee_id}`} target="_blank">{co.employee_name}</Link></td>
                                <td>{co.employee_email}</td>
                                <td>{co.employee_mobile}</td>
                                <td>{co.designation_name}</td>
                                <td><Link to={`/co-kpi-assessment/${co.employee_id}`} target="_blank"><span className="badge bg-success">Click Here</span></Link></td>
                                <td><Link to={`/co-competencies/${co.employee_id}`} target="_blank"><span className="badge bg-success">Click Here</span></Link></td>
                                <td><Link to={`/FormCCO/${co.employee_id}`} target="_blank"><span className="badge bg-success">Click Here</span></Link>
                                </td>
                            </tr>
                            )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
            {/* <div className="row mt-3">
                <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="card">
                        <div className="p-2 text-center">
                            <div className="list-group-item d-flex align-items-center py-3">
                                <div className="icon"><img src="assets/images/user.png" className="w-50"/></div>
                                <div className="content text-start">
                                    <h6 className="fs-10 fw-bold mb-0">Self Feedback</h6>
                                    <h6 className="fs-10 fw-bold mb-0">स्वयं प्रतिपुष्टि</h6>
                                    <span className="mb-0">{profileData?.employee_name}</span>

                                </div>
                            </div>
                            <h6>View Your Own Feedback</h6>
                            <Link to="/feedback"><button className="btn btn-primary text-white fs-7">View Feedback</button></Link>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-4 col-md-6">
                    <div className="card">
                        <div className="p-2 text-center">
                            <div className="list-group-item d-flex align-items-center py-3">
                                <div className="icon"><img src="assets/images/user.png" className="w-50"/></div>
                                <div className="content text-start">
                                    <h6 className="fs-10 fw-bold mb-0">Controlling Officer</h6>
                                    <h6 className="fs-10 fw-bold mb-0">नियंत्रण अधिकारी</h6>
                                    <span className="mb-0">{emp_co.co_name}</span>
                                </div>
                            </div>
                            <h6>View the Feedback of your CO</h6>
                            <Link to="/feedback"><button className="btn btn-primary text-white fs-7">View Feedback</button></Link>
                        </div>
                    </div>
                </div>
                
            </div> */}
        </div>
        </>
    );
};

export default Dashboard;