import React,{useState,useEffect,useRef} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { useParams } from "react-router-dom";
import { API_BASE_URL } from '../../src/constant';
import {getProfileInfo} from './../sessions';

const TopNav = () => {
    const [profileData,setProfileData] = useState({});
    const [pmpStatus,setPmpStatus] = useState([]);
    const [formCStatus,setFormcStatus] = useState('');
    let { empId } = useParams();
    const lock = useRef(true);

    useEffect(() => {
        if(lock){
        lock.current = false;
        getPMPStatus();
        }
        var profile = getProfileInfo();
        setProfileData(profile);
    }, []);

    const getPMPStatus = () => {
        let token = localStorage.usertoken
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API_BASE_URL}Appraisal/get_pmp_status`, requestOptions)
        .then(response => response.json())
        .then((rsp) => { 
            setPmpStatus(rsp.empRating) 
            setFormcStatus(rsp.formCStatus)
        })
        .catch(error => console.log('error', error));   
    }

    const logout = ()=>{
        localStorage.clear();
    }
    return (
        <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
            <div className="btn btn-sm btn-link ps-0 btn-toggle-fullwidth" id="toggle-btn" style={{color:'red'}}>
                <i className="fas fa-bars"></i>
            </div>
            <div className="navbar-brand ps-2">
                <Link to="/dashboard" className="d-flex">
                    <img src="/assets/images/logo.png" className="w-50" alt="acf"/>
                </Link>
            </div>
            {empId == undefined ?
            <section className="step-wizard">
                <ul className="step-wizard-list">
                    <li className={profileData.accountStatus==1?"step-wizard-item":"step-wizard-item current-item"}>
                        <span className="progress-count">1</span>
                        <Link to="/personal-info"><span className="progress-label">Personal Verification</span></Link>
                    </li>
                    <li className={pmpStatus && pmpStatus.emp_competancy_score !=='0'?"step-wizard-item":"step-wizard-item current-item"}>
                        <span className="progress-count">2</span>
                        <Link to="#" disabled="disabled"><span className="progress-label">Competencies</span></Link>
                    </li>
                    <li className={pmpStatus && pmpStatus.emp_kpi_score !=='0'?"step-wizard-item":"step-wizard-item current-item"}>
                        <span className="progress-count">3</span>
                        <Link to="#"><span className="progress-label">KPI</span></Link>
                    </li>
                    <li className={formCStatus!=0?"step-wizard-item":"step-wizard-item current-item"}>
                        <span className="progress-count">4</span>
                        <Link to="#"><span className="progress-label">Form-C</span></Link>
                    </li>
                </ul>
            </section>
            :""}
            <div className="d-flex flex-grow-1 align-items-center">
                <div className="flex-grow-1">
                    <ul className="nav navbar-nav flex-row justify-content-end align-items-center">
                        {/* <li><Link title="Notifications" className="icon-menu" to="/notifications"><i className="fa fa-bell"></i><span className="notification-dot"></span></Link></li>
                        <li><Link title="FAQ" to="/faqs" className="icon-menu"><i className="fa fa-comments"></i></Link></li> */}
                        <li><Link to="/" title="logout" className="icon-menu" onClick={()=>logout()} ><i className="fa fa-sign-out"></i></Link></li>
                        <li className="fw-semibold fs-11">Date: {moment().format('D MMM YYYY')}<br/>FY : 2023-24</li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
    );
};

export default TopNav;