import React,{useState,useEffect,useRef} from 'react';
import { API_BASE_URL } from '../../src/constant';
import {Link} from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

const Feedback2 = () => {
const [textInputs, setTextInputs] = useState([]);
const [empFeedback, setEmpFeedback] = useState({});
const [savedFeedback, getSavedfeedback] = useState([]);
const [twoAchievements, gettwoAchievements] = useState('');
const [twoImprovement, gettwoImprovement] = useState('');
const [loder, setLoder] = useState(false);
const lock = useRef(true);

const handleTextChange = (index, event) => {
const newTextInputs = [...textInputs];
newTextInputs[index] = event.target.value;
setTextInputs(newTextInputs);
};

const charactersLeft = (index) => {
if (textInputs[index]) {
    return 500 - textInputs[index].length;
}
return 500;
};

const getfeedBack = (e) =>{
    setEmpFeedback({
        ...empFeedback,
        [e.target.name]: e.target.value
    });
}

const savefeedBack = ()=>{
    setLoder(true)
    if(Object.values(empFeedback).length < 7 && Object.values(textInputs).length <2){
        setLoder(false)
        alert('Please  answer all questions');
        return false;
    }
    let feedBackData = {
        "feedback": [
        {
            "questions": "Have you filled your appraisal form by yourself?",
            "answer": empFeedback['q1']
        },
        {
            "questions": "Did you face any difficulty while filling your appraisal form.",
            "answer": empFeedback['q2']
        },
        {
            "questions": "Did your controlling officer inform you regarding the time and venue of the discussion in advance?",
            "answer": empFeedback['q3']
        },
        {
            "questions": "Did your controlling officer discuss while reviewing your performance?",
            "answer": empFeedback['q4']
        },
        {
            "questions": "What was the mode of discussion?",
            "answer": empFeedback['q5']
        },
        {
            "questions": "How much time did your controlling officer devote to your performance review?",
            "answer": empFeedback['q6']
        },
        {
            "questions": "What are two achievements highlighted by your controlling officer?",
            "answer": textInputs[0]
        },
        {
            "questions": "What are two area of improvement suggestedby your controlling officer?",
            "answer": textInputs[1]
        },
        {
            "questions": "How would you like to express yourself after performance review?",
            "answer": empFeedback['q7']
        }
        ]
    }
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    let raw = JSON.stringify({
        "feedback": feedBackData,
    });
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/save_emp_feedback_data`, requestOptions)
    .then(response => response.json())
    .then(result => {
        alert(result.message);
        setLoder(false)
        window.location.href='./dashboard'
    })
    .catch(error => console.log('error', error))
}

const getEmployeeFeedback = () => {
    let token = localStorage.usertoken
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch(`${API_BASE_URL}Appraisal/get_feedback`, requestOptions)
    .then(response => response.json())
    .then((rsp) => { 
        getSavedfeedback(rsp.feedbackData.feedback.feedback);
        gettwoAchievements(rsp.feedbackData.feedback.feedback[6].answer)
        gettwoImprovement(rsp.feedbackData.feedback.feedback[7].answer)
    })
    .catch(error => console.log('error', error));   
}
useEffect(()=>{
    if(lock){
        lock.current = false;
        getEmployeeFeedback();
    }
},[])
return (
<div className="container-fluid">
    <div className="block-header py-lg-4 py-3">
        <div className="row g-3">
            <div className="col-md-12 col-sm-12">
                <h5 className="card-title">Feedback</h5>
                <h5 className="card-title">प्रतिक्रिया</h5>
            </div>
        </div>
    </div>
    <div className="row g-2 clearfix row-deck">
        <div className="card" style={{height:"970px"}}>
            <div className='row' style={{height:"70px",paddingTop:"10px",paddingLeft:"10px"}}>
                <div className='col-12'>
                <strong>Have you filled your appraisal form by yourself?<br/>क्या आपने अपना मूल्यांकन फॉर्म स्वयं भरा है?</strong>
                </div>
                <div className='col-12' >
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[0].answer=='Yes'}/>:<input class="form-check-input" type="radio" name="q1" id="inlineRadio1" value="Yes" onChange={(e)=>getfeedBack(e)}/>}
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[0].answer=='No'} />:<input class="form-check-input" type="radio" name="q1" id="inlineRadio1" value="No" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">No</label>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='row' style={{height:"70px",paddingTop:"10px",paddingLeft:"10px"}}>
                <div className='col-12'>
                <strong>Did you face any difficulty while filling your appraisal form.<br/>क्या आपको अपना मूल्यांकन फॉर्म भरते समय किसी कठिनाई का सामना करना पड़ा?</strong>
                </div>
                <div className='col-12' >
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[1].answer=='Yes'}/>:<input class="form-check-input" type="radio" name="q2" id="inlineRadio1" value="Yes" onChange={(e)=>getfeedBack(e)}/>}
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[1].answer=='No'}/>:<input class="form-check-input" type="radio" name="q2" id="inlineRadio1" value="No" onChange={(e)=>getfeedBack(e)}/>}
                        <label class="form-check-label" for="inlineRadio1">No</label>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='row' style={{height:"70px",paddingTop:"10px",paddingLeft:"10px"}}>
                <div className='col-12'>
                <strong>Did your controlling officer inform you regarding the time and venue of the discussion in advance?<br/>क्या आपके नियंत्रण अधिकारी ने आपको चर्चा के समय और स्थान के बारे में पहले से सूचित किया था?</strong>
                </div>
                <div className='col-12' >
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[2].answer=='Yes'}/>:<input class="form-check-input" type="radio" name="q3" id="inlineRadio1" value="Yes" onChange={(e)=>getfeedBack(e)}/>}
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[2].answer=='No'} />:<input class="form-check-input" type="radio" name="q3" id="inlineRadio1" value="No" onChange={(e)=>getfeedBack(e)}/>}
                        <label class="form-check-label" for="inlineRadio1">No</label>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='row' style={{height:"70px",paddingTop:"10px",paddingLeft:"10px"}}>
                <div className='col-12'>
                <strong>Did your controlling officer discuss while reviewing your performance?<br/>क्या आपके नियंत्रक अधिकारी ने चर्चा करते समय आपके प्रदर्शन की समीक्षा की?</strong>
                </div>
                <div className='col-12' >
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[3].answer=='Yes'} />:<input class="form-check-input" type="radio" name="q4" id="inlineRadio1" value="Yes" onChange={(e)=>getfeedBack(e)}/>}
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[3].answer=='No'}/>:<input class="form-check-input" type="radio" name="q4" id="inlineRadio1" value="No" onChange={(e)=>getfeedBack(e)}/>}
                        <label class="form-check-label" for="inlineRadio1">No</label>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='row' style={{height:"70px",paddingTop:"10px",paddingLeft:"10px"}}>
                <div className='col-12'>
                <strong>What was the mode of discussion?<br/>चर्चा का तरीका क्या था?</strong>
                </div>
                <div className='col-12' >
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[4].answer=='Face to Face'} />:<input class="form-check-input" type="radio" name="q5" id="inlineRadio1" value=">Face to Face" onChange={(e)=>getfeedBack(e)}/>}
                        <label class="form-check-label" for="inlineRadio1">Face to Face</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio"  checked={savedFeedback[4].answer=='Telephone'}  />:<input class="form-check-input" type="radio" name="q5" id="inlineRadio1" value="Telephone" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">Telephone</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[4].answer=='Video Conference'} />:<input class="form-check-input" type="radio" name="q5" id="inlineRadio1" value="Video Conference" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">Video Conference</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[4].answer=='None'}/>:<input class="form-check-input" type="radio" name="q5" id="inlineRadio1" value="None" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">None</label>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='row' style={{height:"70px",paddingTop:"10px",paddingLeft:"10px"}}>
                <div className='col-12'>
                <strong>How much time did your controlling officer devote to your performance review?<br/>आपके नियंत्रण अधिकारी ने आपके प्रदर्शन की समीक्षा के लिए कितना समय दिया?</strong>
                </div>
                <div className='col-12' >
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[5].answer=='Less then 10 min'} />:<input class="form-check-input" type="radio" name="q6" id="inlineRadio1" value="Less then 10 min" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">Less then 10 min</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked={savedFeedback[5].answer=='10 to 30 min'} />:<input class="form-check-input" type="radio" name="q6" id="inlineRadio1" value="10 to 30 min" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">10 to 30 min</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio"  checked={savedFeedback[5].answer=='More then 30 min'}/>:<input class="form-check-input" type="radio" name="q6" id="inlineRadio1" value="More then 30 min" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">More then 30 min</label>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='row' style={{height:"100px",paddingTop:"10px",paddingLeft:"10px"}}>
                <div className='col-12'>
                <strong>What are two achievements highlighted by your controlling officer?<br/>आपके नियंत्रण अधिकारी द्वारा किन दो उपलब्धियों पर प्रकाश डाला गया है?</strong>
                </div>
                <div className='col-12' >
                    <div class="form-group">
                        <textarea class="form-control" id="exampleFormControlTextarea1" maxLength="500" value={textInputs[0] || twoAchievements} onChange={(event) => handleTextChange(0, event)} ></textarea><span>Characters Left: {charactersLeft(0)}</span>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='row' style={{height:"100px",paddingTop:"10px",paddingLeft:"10px"}}>
                <div className='col-12'>
                <strong>What are two area of improvement suggestedby your controlling officer?<br/>आपके नियंत्रण अधिकारी द्वारा सुधार के कौन से दो क्षेत्र सुझाए गए हैं?</strong>
                </div>
                <div className='col-12' >
                    <div class="form-group">
                        <textarea class="form-control" id="exampleFormControlTextarea2" maxLength="500" value={textInputs[1] || twoImprovement} onChange={(event) => handleTextChange(1, event)} ></textarea><span>Characters Left: {charactersLeft(1)}</span>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='row' style={{height:"70px",paddingTop:"10px",paddingLeft:"10px"}}>
                <div className='col-12'>
                <strong>How would you like to express yourself after performance review?<br/>प्रदर्शन समीक्षा के बाद आप स्वयं को किस प्रकार अभिव्यक्त करना चाहेंगे?</strong>
                </div>
                <div className='col-12' >
                    <div class="form-check form-check-inline">
                        {savedFeedback.length?<input class="form-check-input" type="radio" checked = {savedFeedback[8].answer=='Motivated'} />:<input class="form-check-input" type="radio" name="q7" id="inlineRadio1" value="Motivated" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">Motivated</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio" checked = {savedFeedback[8].answer=='Did not make difference'} />:<input class="form-check-input" type="radio" name="q7" id="inlineRadio1" value="Did not make difference" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">Did not make difference</label>
                    </div>
                    <div class="form-check form-check-inline">
                    {savedFeedback.length?<input class="form-check-input" type="radio"  checked = {savedFeedback[8].answer=='Demotivated'} />:<input class="form-check-input" type="radio" name="q7" id="inlineRadio1" value="Demotivated" onChange={(e)=>getfeedBack(e)} />}
                        <label class="form-check-label" for="inlineRadio1">Demotivated</label>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-8">
                <ThreeDots
                    visible={loder}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                </div>
                <div className="col-2"></div>
                <div className="col-2">
                    {savedFeedback && savedFeedback.length?'':<button disabled={loder} onClick={()=>{savefeedBack()}} className="btn btn-primary text-white">Submit</button>}
                </div>
            </div><br/>
        </div>
    </div>
</div>
);
};

export default Feedback2;